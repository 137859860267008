export const PRIMARY = '#e18008';
export const PRIMARY_ACTIVE = '#E69C00';
export const SECONDARY = '#1f5359';
export const SECONDARY_DARKENED = '#063A40';
export const FONT_PRIMARY = '#eee';
export const FONT_SECONDARY = '#a197a1';
export const FADE_WHITE = 'rgba(255, 255, 255, 0.1)';
export const FADE_WHITE_FOCUS = 'rgba(255, 255, 255, 0.2)';
export const FADE_BLACK = 'rgba(126, 126, 126, 0.2)';
export const FADE_BLACK_FOCUS = 'rgba(126, 126, 126, 0.4)';
export const LINK_SECONDARY_INACTIVE = '#726672';
export const LINK_SECONDARY_ACTIVE = '#fff';
export const SUCCESS = '#28a745';
export const WARNING = '#ffc107';
export const ERROR = '#dc3545';
export const PROGRESS_FOREGROUND = '#fff';
export const PROGRESS_BACKGROUND = '#777';
export const BUTTON_BACKGROUND = '#999';
export const BUTTON_BACKGROUND_ACTIVE = '#bbb';

// new ui 2022 colors
export const TEAL_MINUS_60 = '#0D2123';
export const TEAL_MINUS_40 = '#133134';
export const TEAL_MINUS_20 = '#1A4246';
export const TEAL = '#205257';
export const TEAL_PLUS_20 = '#4D7579';
export const TEAL_PLUS_40 = '#79979A';
export const TEAL_PLUS_60 = '#A6BABC';
export const TEAL_PLUS_80 = '#D2DCDD';

export const ICON_GRAY = '#9e9d9d';
export const DARK_BACKGROUND = '#061011';
export const STOD_2_WHITE = '#FFFFFF';
export const HIGHLIGHT_COLOR = '#26b3ba1a';

// new ui 2022 mixins
// if you know of a better way to color svg's let me know
export const TEAL_PLUS_40_MIXIN = 'invert(60%) sepia(13%) saturate(463%) hue-rotate(137deg) brightness(92%) contrast(96%)';
export const DARK_BOX_SHADOW = '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 11px 15px -7px rgba(0, 0, 0, 0.3), 0px 24px 38px 3px rgba(0, 0, 0, 0.24)';
export const TEAL_WITH_15_PERCENT_GRADIENT = '#27868B'; // should problably use a real gradient here
