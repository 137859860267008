import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Navigation from './Navigation/Navigation';
import { CurrentUser } from './CurrentUser/CurrentUser';
import { MenuIcon } from './MenuIcon/MenuIcon';
import { Hidden } from '../../ui/core/Hidden/hidden';
import { ProfileImageClickHandler } from './ProfileImage';
import { UserContext } from '../../contexts/UserContext';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';
import {
	HeaderContainer,
	HeaderInnerContainer,
	LeftMenuItemContainer,
	LoginLogoutButton,
	MenuContainer,
	MenuLogoContainer,
	MiddleItemContainer,
	RightContainer,
	RightMenuItemContainer,
	SearchLink,
} from './Header.styles';
import { MobileScreensContext } from '../../contexts/MobileScreensContext';
import { useHasMounted } from '../../hooks/useHasMounted';
import { HeaderBuyLinkButton } from '../../ui/core/Buttons/Buttons';

export const Header: React.FC = () => {
	const { requestAuthentication } = useContext(UserContext);
	const { asPath } = useRouter();
	const [LogoType, setLogoType] = useState<ICON_TYPE>(ICON_TYPE.STOD2_LOGO);

	useEffect(() => {
		if (asPath?.includes('episodes') || asPath?.includes('children') || asPath?.includes('movies') || asPath?.includes('stod2plus')) {
			setLogoType(ICON_TYPE.STOD2_PLUS_LOGO);
		} else {
			setLogoType(ICON_TYPE.STOD2_LOGO);
		}
	}, [asPath]);

	const onClick = useCallback(() => {
		requestAuthentication();
	}, [requestAuthentication]);

	return (
		<HeaderContainer>
			<HeaderInnerContainer>
				<MenuContainer>
					<LeftMenuItemContainer>
						<Link href={'/'}>
							<MenuLogoContainer>
								<Icon type={LogoType} height="100%" />
							</MenuLogoContainer>
						</Link>
					</LeftMenuItemContainer>
					<MiddleItemContainer>
						<Hidden below="tabletLandscape">
							<Navigation />
						</Hidden>
					</MiddleItemContainer>
					<RightMenuItemContainer>
						<Hidden above="tabletLandscape">
							<RightContainer>
								<MobileOptions onClick={onClick} />
								<MenuIcon />
							</RightContainer>
						</Hidden>
						<Hidden below="tabletLandscape">
							<RightContainer>
								<Link href={'/search'}>
									<SearchLink href={'/search'}>
										<Icon type={ICON_TYPE.SEARCH_SM} />
									</SearchLink>
								</Link>
								<DesktopOptions onClick={onClick} />
							</RightContainer>
						</Hidden>
					</RightMenuItemContainer>
				</MenuContainer>
			</HeaderInnerContainer>
		</HeaderContainer>
	);
};

const MobileOptions = ({ onClick }) => {
	const hasMounted = useHasMounted();
	const { t } = useTranslation();
	const { isLoggedIn, user } = useContext(UserContext);
	const { toggleProfile } = useContext(MobileScreensContext);

	if (!hasMounted) {
		return null;
	}

	return (
		<>
			{isLoggedIn ? (
				<>
					<ProfileImageClickHandler handleClick={toggleProfile} userImage={user?.selectedProfile?.image} />
				</>
			) : (
				<>
					<LoginLogoutButton onClick={onClick}>{t('User.Login')}</LoginLogoutButton>
				</>
			)}
		</>
	);
};

const DesktopOptions = ({ onClick }) => {
	const hasMounted = useHasMounted();
	const { t } = useTranslation();
	const { isLoggedIn, user } = useContext(UserContext);
	if (!hasMounted) {
		return null;
	}
	return (
		<>
			{isLoggedIn ? (
				<>
					<Link href={'/my-list'}>
						<SearchLink href={'/my-list'}>
							<Icon type={ICON_TYPE.MY_LIST} />
						</SearchLink>
					</Link>
					<CurrentUser user={user} />
				</>
			) : (
				<>
					<LoginLogoutButton onClick={onClick}>{t('User.Login')}</LoginLogoutButton>
					<HeaderBuyLinkButton url={'https://stod2.is/askrift/'} text={t('Buttons.BuySubscription')} />
				</>
			)}
		</>
	);
};

export default Header;
