import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { Entitlement, Exception } from '@vodafoneis/sjonvarpskjarni-js-lib';
import PlaylistItem from '../models/PlaylistItem';
import { ENTITLEMENT_UPDATE_INTERVAL } from '../config/constants';
import PlaybackStartingState from '../components/PlaybackStarting/PlaybackStartingState';
import { deleteEntitlement, updateEntitlement } from '../api/entitlement';

type EntitlementHookParamsType = {
	playbackStartingState: PlaybackStartingState;
	playlistItem: PlaylistItem;
};

const { ENTITLEMENT } = PlaybackStartingState;

export function useEntitlement({ playbackStartingState, playlistItem }: EntitlementHookParamsType) {
	const prevPlaylistItem = usePrevious<PlaylistItem>(playlistItem);
	const [entitlement, setEntitlement] = useState<Entitlement>(null);
	const [force, setForce] = useState<boolean>(false);
	const [exception, setException] = useState<Exception>(null);
	const mutex = useRef(false);

	useEffect(() => {
		(async () => {
			if (prevPlaylistItem && playlistItem !== prevPlaylistItem && entitlement) {
				try {
					await deleteEntitlement(entitlement.id);
				} catch (e) {}

				setEntitlement(null);
				setForce(false);
			}
		})();
	}, [entitlement, playlistItem, prevPlaylistItem]);

	useEffect(() => {
		(async () => {
			if (mutex.current) return;

			mutex.current = true;

			if (playbackStartingState === ENTITLEMENT && playlistItem && !entitlement) {
				try {
					const newEntitlement = await playlistItem.createEntitlement(force);
					setEntitlement(newEntitlement);
				} catch (e) {
					setEntitlement(null);
					setException(e);
				}
			}

			mutex.current = false;
		})();
	}, [entitlement, force, playbackStartingState, playlistItem]);

	useEffect(() => {
		const interval = setInterval(async () => {
			if (entitlement) {
				try {
					const data = await updateEntitlement(entitlement.id);
					entitlement.expires = data?.expires ?? entitlement.expires;
					setEntitlement(entitlement);
				} catch (e) {
					setEntitlement(null);
					setException(e);
				}
			}
		}, ENTITLEMENT_UPDATE_INTERVAL * 1000);

		return () => clearInterval(interval);
	}, [entitlement]);

	const forceEntitlement = useCallback(() => setForce(true), []);

	return { entitlement, exception, forceEntitlement };
}
