import React, { createContext, useContext, useEffect, useMemo, useState, ReactElement } from 'react';
import { Language, UserSettings, useUserSettings } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { UserContext } from './UserContext';
import { DEFAULT_LANGUAGE, USER_SETTINGS_POLL_INTERVAL } from '../config/constants';

type SettingsContextProps = {
	password: string;
	setPassword: (password: string) => void;
	pinCode: string;
	setPinCode: (pinCode: string) => void;
	pinCodeConfirm: string;
	setPinCodeConfirm: (pinCodeConfirm: string) => void;
	settings: UserSettings | null;
};

export const SettingsContext = createContext<Partial<SettingsContextProps>>({});

export const SettingsContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
	const { settings } = useUserSettings(USER_SETTINGS_POLL_INTERVAL);
	const { user, setLanguage } = useContext(UserContext);

	const [password, setPassword] = useState(null);
	const [pinCode, setPinCode] = useState(null);
	const [pinCodeConfirm, setPinCodeConfirm] = useState(null);

	useEffect(() => {
		if (user?.selectedProfile?.language) {
			setLanguage(user?.selectedProfile?.language?.toLocaleLowerCase());
		} else if (settings?.language) {
			setLanguage(settings.language === Language[Language.EN] ? 'en' : DEFAULT_LANGUAGE);
		} else {
			setLanguage(DEFAULT_LANGUAGE);
		}
	}, [setLanguage, settings, user]);

	const value = useMemo(
		() => ({
			password,
			setPassword,
			pinCode,
			setPinCode,
			pinCodeConfirm,
			setPinCodeConfirm,
			settings,
		}),
		[password, pinCode, pinCodeConfirm, settings]
	);

	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
