import styled from 'styled-components';
import { sharedFontStyles } from '../../../ui/core/Typography/typography.styles';
import { media } from '../../../ui/utils/media';

export const CurrentUserContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	cursor: pointer;
`;

export const CurrentUserName = styled.div`
	${sharedFontStyles};
	margin-right: 10px;
	margin-left: 16px;
	max-width: 120px;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	${media.desktopUp} {
		font-size: 18px;
		line-height: 22px;
	}
	${media.desktopXLargeUp} {
		font-size: 24px;
		line-height: 29px;
	}
	text-transform: capitalize;
`;

export const HeaderMenuContainerRel = styled.div`
	position: relative;
`;

export const HeaderMenuContainer = styled.div`
	position: absolute;
	right: 0;
	top: 2px;
	z-index: 2;
	width: 322px;
`;
