import { Client } from '@vodafoneis/sjonvarpskjarni-js-lib';

const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION;

const client = new Client({
	baseUrl: `${API_HOST.replace(/\/*$/, '')}/`,
	defaultHeaders: {
		'Content-Type': 'application/json',
		'Accept-Version': API_VERSION,
	},
});

export default client;
