import styled from 'styled-components';
import { media } from '../../ui/utils/media';

export const EmptyContainer = styled.main``;

export const LayoutContainer = styled.main`
	width: 100%;
	min-height: 100vh;
	padding-left: ${({ theme }) => theme.screenLayoutGutters.mobile.left}px;
	padding-right: ${({ theme }) => theme.screenLayoutGutters.mobile.right}px;
	margin-bottom: 48px;
	${media.tabletPortraitUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.tabletPortrait.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.tabletPortrait.right}px;
		margin-bottom: 60px;
	}
	${media.tabletLandscapeUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.tabletLandscape.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.tabletLandscape.right}px;
		margin-bottom: 40px;
	}
	${media.desktopUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktop.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktop.right}px;
		margin-bottom: 108px;
	}
	${media.desktopLargeUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktopLarge.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktopLarge.right}px;
		margin-bottom: 144px;
	}
	${media.desktopXLargeUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktopXLarge.left}vw;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktopXLarge.right}vw;
		margin-bottom: 192px;
	}
`;

export const ProfileContainer = styled.div<{ show: boolean }>`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	transition: transform 0.3s ease-in-out;
	transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(100%)')};
	z-index: 9;
`;
