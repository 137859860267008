import { useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { Exception, Rental } from '@vodafoneis/sjonvarpskjarni-js-lib';
import PlaylistItem from '../models/PlaylistItem';
import MoviePlaylistItem from '../models/MoviePlaylistItem';
import PlaybackStartingState from '../components/PlaybackStarting/PlaybackStartingState';

type RentalHookParamsType = {
	playbackStartingState: PlaybackStartingState;
	playlistItem: PlaylistItem;
};

const { RENTAL } = PlaybackStartingState;

export function useRental({ playbackStartingState, playlistItem }: RentalHookParamsType) {
	const prevPlaylistItem = usePrevious(playlistItem);
	const [rental, setRental] = useState<Rental>(null);
	const [exception, setException] = useState<Exception>(null);
	const mutex = useRef(false);
	useEffect(() => {
		(async () => {
			if (prevPlaylistItem && playlistItem !== prevPlaylistItem && rental) {
				// TODO: Cancel rental on error.
				// try {
				// 	rental.cancel();
				// } catch (e) {}

				setRental(null);
			}
		})();
	}, [playlistItem, prevPlaylistItem, rental]);

	useEffect(() => {
		(async () => {
			if (mutex.current) return;

			mutex.current = true;

			if (playbackStartingState === RENTAL && playlistItem instanceof MoviePlaylistItem && !rental) {
				try {
					const newRental = await playlistItem.createRental();
					setRental(newRental);
				} catch (e) {
					setRental(null);
					setException(e);
				}
			}

			mutex.current = false;
		})();
	}, [playbackStartingState, playlistItem, prevPlaylistItem, rental]);

	return { rental, exception };
}
