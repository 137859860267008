import React, { FC } from 'react';
import { Icon, ICON_TYPE } from '../../../components/IconComponent/IconComponent';
import { StyledActionBtn, StyledLinkBtn, StyledMainBuyLink, StyledInstructionsBtn, StyledButton, StrapiButtonLink } from './Buttons.style';
import { ThemeColorProp, ThemeInterface } from '../../theme/theme';

type LinkBtnProps = {
	text: string;
	url: string;
};

export const HeaderBuyLinkButton: FC<LinkBtnProps> = ({ text, url }) => (
	<StyledLinkBtn href={url} color="white" target="_blank" rel="noopener noreferrer">
		{text}
	</StyledLinkBtn>
);

export const InstructionsButton: FC<LinkBtnProps> = ({ text, url }) => (
	<StyledInstructionsBtn href={url} color="white" target="_blank" rel="noopener noreferrer">
		{text}
	</StyledInstructionsBtn>
);

export const MainBuyLinkButton: FC<LinkBtnProps> = ({ text, url }) => (
	<StyledMainBuyLink hoverBgColor={null} bgColor={null} href={url} color="white" target="_blank" rel="noopener noreferrer">
		{text}
	</StyledMainBuyLink>
);

type ActionBtnProps = {
	type: ICON_TYPE;
	handleClick: () => void;
};

export const ActionBtn: FC<ActionBtnProps> = ({ type, handleClick }) => (
	<StyledActionBtn onClick={handleClick}>
		<Icon type={type} />
	</StyledActionBtn>
);

export type ButtonProps = {
	text: string;
	handleClick: (event: React.SyntheticEvent) => void;
	bgColor?: keyof ThemeInterface['colors'];
	bgHoverColor?: keyof ThemeInterface['colors'];
	borderColor?: keyof ThemeInterface['colors'];
	textColor?: keyof ThemeInterface['colors'];
	iconType?: ICON_TYPE;
	fade?: boolean;
};

export const Button: FC<ButtonProps> = ({ text, iconType, bgColor, bgHoverColor, borderColor, textColor, fade, handleClick }) => (
	<StyledButton onClick={handleClick} bgColor={bgColor} bgHoverColor={bgHoverColor} borderColor={borderColor} textColor={textColor} fade={fade}>
		{iconType && <Icon type={iconType} />}
		{text}
	</StyledButton>
);

type ButtonGroupPops = {
	text: string;
	type: 'primary' | 'secondary' | 'tertiary' | 'hyperlink';
	url: string;
	color?: ThemeColorProp;
};

export const ButtonGroup: FC<ButtonGroupPops> = ({ text, type, url, color }) => {
	switch (type) {
		case 'primary':
			return (
				<StrapiButtonLink hoverBgColor={'passionPurpleDark'} textColor={'white'} bgColor={'passionPurple'} href={url} target="_blank" rel="noopener noreferrer">
					{text}
				</StrapiButtonLink>
			);
		case 'secondary':
			return (
				<StrapiButtonLink hoverBgColor={'btnPrimaryLight'} textColor={'white'} bgColor={'teal'} href={url} target="_blank" rel="noopener noreferrer">
					{text}
				</StrapiButtonLink>
			);
		case 'tertiary':
			return (
				<StrapiButtonLink hoverBgColor={'tealPlus80'} textColor={'black'} bgColor={'white'} href={url} target="_blank" rel="noopener noreferrer">
					{text}
				</StrapiButtonLink>
			);
		case 'hyperlink':
			return (
				<StrapiButtonLink hoverBgColor={null} textColor={color} bgColor={'transparent'} href={url} target="_blank" rel="noopener noreferrer" isHyper>
					{text}
					<Icon type={ICON_TYPE.ARROW_RIGHT} />
				</StrapiButtonLink>
			);
		default:
			return (
				<StrapiButtonLink hoverBgColor={'btnPrimaryLight'} textColor={'white'} bgColor={'teal'} href={url} target="_blank" rel="noopener noreferrer">
					{text}
				</StrapiButtonLink>
			);
	}
};
