import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum LoginBlockedReason {
	NO_LOCAL_STORAGE = 'NO_LOCAL_STORAGE',
}

function useMessageForReason(reason) {
	const { t } = useTranslation();

	return useMemo(() => {
		const { NO_LOCAL_STORAGE } = LoginBlockedReason;

		switch (reason) {
			case NO_LOCAL_STORAGE:
				return t('Login.Error.LocalStorage');
			default:
				return t('Login.Error.Blocked');
		}
	}, [reason, t]);
}

type LoginBlockedProps = {
	reason: LoginBlockedReason;
};

export const LoginBlocked: React.FC<LoginBlockedProps> = ({ reason }) => {
	const message = useMessageForReason(reason);

	return <div className={'alert alert-warning'}>{message}</div>;
};
