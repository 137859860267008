import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from '@vodafoneis/sjonvarpskjarni-js-lib';
import possibleTypes from '../possibleTypes.json';

const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION;

export const client = createApolloClient({
	host: API_HOST,
	apiVersion: API_VERSION,
	possibleTypes,
});

export function ApolloClientProvider({ children }) {
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
