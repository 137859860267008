import React, { useContext, FC } from 'react';
import { MobileScreensContext } from '../../../contexts/MobileScreensContext';
import { MenuIconWrapper, MenuIconContainerTop, LineTop, MenuIconContainerBottom, LineBottom } from './MenuIcon.styles';

export const MenuIcon: FC = () => {
	const { showMobileMenuScreen, toggleMobileMenu } = useContext(MobileScreensContext);

	return (
		<MenuIconWrapper onClick={toggleMobileMenu}>
			<MenuIconContainerTop open={showMobileMenuScreen}>
				<LineTop open={showMobileMenuScreen} />
			</MenuIconContainerTop>
			<MenuIconContainerBottom open={showMobileMenuScreen}>
				<LineBottom open={showMobileMenuScreen} />
			</MenuIconContainerBottom>
		</MenuIconWrapper>
	);
};
