import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Device from '../models/Device';

const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY;
const VERSION = process.env.NEXT_PUBLIC_VERSION;

const Client = Bugsnag.start({
	apiKey: BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact()],
	appVersion: VERSION,
	autoTrackSessions: false,
	metadata: {
		browser: typeof window !== 'undefined' ? Device.detectBrowserInfo() : null,
	},
});

const ErrorBoundary = Client.getPlugin('react').createErrorBoundary(React);

export { Client, ErrorBoundary };
