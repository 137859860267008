import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { Exception, RentalFailedException } from '@vodafoneis/sjonvarpskjarni-js-lib';
import ChannelPlaylistItem from '../models/ChannelPlaylistItem';
import MoviePlaylistItem from '../models/MoviePlaylistItem';
import { generateBasicInfoForPlaylistItem, identify, track } from '../utils/Analytics';
import { UserContext } from './UserContext';
import { PlaybackContext } from './PlaybackContext';
import { AnalyticsEvent } from '../utils/AnalyticsEvent';
import { AnalyticsSeverity } from '../utils/AnalyticsSeverity';
import { isForChildren } from '../utils/eventUtils';

const { VOD_START, VOD_STOP, VOD_ERROR, CHANNEL_START, CHANNEL_STOP, CHANNEL_ERROR, RENTAL_ERROR, TIMESHIFT_STOP } = AnalyticsEvent;

export const AnalyticsContext = createContext({
	trackPlaybackException: (exception: Exception, errorSeverity: string) => {},
});

export const AnalyticsContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
	const { playlistItem, prevPlaylistItem, exception: playbackException, initialPlaybackPosition, prevInitialPlaybackPosition, playbackEventState } = useContext(PlaybackContext);
	const { user } = useContext(UserContext);

	// Update Segment with user information
	useEffect(() => {
		if (!user?.isAnonymous()) {
			identify(user);
		}

		return () => {
			identify(null);
		};
	}, [user]);

	useEffect(() => {
		if (prevPlaylistItem === playlistItem) return;

		if (prevPlaylistItem) {
			const basicInfo = generateBasicInfoForPlaylistItem(prevPlaylistItem);
			if (user.profileId) basicInfo.profileId = user.profileId;

			if (prevPlaylistItem instanceof ChannelPlaylistItem) {
				track(CHANNEL_STOP, basicInfo);
			} else if (prevPlaylistItem instanceof MoviePlaylistItem) {
				track(VOD_STOP, {
					...basicInfo,
					startPosition: prevInitialPlaybackPosition > 0 ? prevInitialPlaybackPosition : 0,
					stopPosition: Math.round(prevPlaylistItem.lastKnownPosition),
				});
			}

			const item = prevPlaylistItem as ChannelPlaylistItem;
			if (playbackEventState) {
				track(TIMESHIFT_STOP, {
					episodeNumber: playbackEventState.episodeNumber,
					seasonNumber: playbackEventState.seasonNumber,
					itemId: item.channel.operatorRef,
					itemTitle: item.channel.title,
					playbackSessionId: item.playbackSessionId,
					programDuration: playbackEventState.getDuration(),
					itemChildren: isForChildren(playbackEventState.genre),
					programId: playbackEventState.id,
					programStart: playbackEventState.startTime.getTime(),
					programTitle: playbackEventState.title,
					startPosition: prevInitialPlaybackPosition > 0 ? prevInitialPlaybackPosition : 0,
					stopPosition: Math.round(prevPlaylistItem.lastKnownPosition),
				});
			}

		}

		if (playlistItem) {
			const basicInfo = generateBasicInfoForPlaylistItem(playlistItem);
			if (user.profileId) basicInfo.profileId = user.profileId;

			if (playlistItem instanceof ChannelPlaylistItem) {
				track(CHANNEL_START, basicInfo);
			} else if (playlistItem instanceof MoviePlaylistItem) {
				track(VOD_START, {
					...basicInfo,
					startPosition: initialPlaybackPosition > 0 ? initialPlaybackPosition : 0,
				});
			}
		}
	}, [prevInitialPlaybackPosition, playlistItem, prevPlaylistItem, initialPlaybackPosition, user, playbackEventState]);

	const trackPlaybackException = useCallback(
		(exception: Exception, errorSeverity: string) => {
			if (playlistItem) {
				const properties = {
					...generateBasicInfoForPlaylistItem(playlistItem),
					errorName: exception.name,
					errorMessage: exception.message,
					errorSeverity,
					errorPosition: Math.round(playlistItem.lastKnownPosition),
				};

				if (exception instanceof RentalFailedException) {
					track(RENTAL_ERROR, properties);
				} else if (playlistItem instanceof ChannelPlaylistItem) {
					track(CHANNEL_ERROR, properties);
				} else if (playlistItem instanceof MoviePlaylistItem) {
					track(VOD_ERROR, properties);
				}
			}
		},
		[playlistItem]
	);

	useEffect(() => {
		if (playbackException) {
			const severity = ['CouldNotCreateEntitlementException', 'NotPlayableException', 'RentalFailedException'].includes(playbackException.name)
				? AnalyticsSeverity.CRITICAL
				: AnalyticsSeverity.MINOR;

			trackPlaybackException(playbackException, severity);
		}
	}, [playbackException, trackPlaybackException]);

	const value = useMemo(() => ({ trackPlaybackException }), [trackPlaybackException]);

	return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};
