import React from 'react';
import styled from 'styled-components';
import { media } from '../../../ui/utils/media';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	height: 32px;
	width: 32px;
	border-radius: 6px;
	${media.tabletLandscapeUp} {
		border-radius: 4.27px;
	}
	${media.desktopUp} {
		height: 40px;
		width: 40px;
		border-radius: 6px;
	}
	${media.desktopXLargeUp} {
		height: 53.3px;
		width: 53.3px;
	}
	background-color: ${({ theme }) => theme.colors.tealMinus60};
`;

const ClickableContainer = styled(Container)`
	cursor: pointer;
`;

export const HeaderMenuIconContainer: React.FC<{ children: React.ReactElement }> = ({ children }) => {
	return <Container>{children}</Container>;
};

export const HeaderMenuIconContainerClickHandler: React.FC<{ children: React.ReactElement; handleClick: () => void }> = ({ children, handleClick }) => {
	return <ClickableContainer onClick={handleClick}>{children}</ClickableContainer>;
};
