enum PlaybackStartingState {
	INITIALIZING = 'initializing',
	PREPARING = 'preparing',
	TRANSITIONING = 'transitioning',
	RENTAL = 'rental',
	ENTITLEMENT = 'entitlement',
	PIN = 'pin',
	ENTITLEMENT_LIMIT = 'entitlementLimit',
	ERROR = 'error',
}

export default PlaybackStartingState;
