import styled from 'styled-components';
import { sharedFontStyles } from '../../../ui/core/Typography/typography.styles';

export const HeaderMenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	background-color: rgba(6, 16, 17, 0.9);
	box-shadow: ${({ theme }) => theme.misc.boxShadow};
	backdrop-filter: blur(23px);
`;

export const Row = styled.div`
	display: flex;
	height: 72px;
	width: 100%;
	padding: 0 16px;
	align-items: center;
	gap: 16px;
`;

export const Text = styled.p`
	margin: 0;
	padding: 0;
	${sharedFontStyles};
	max-width: 202px;
`;

export const Profiles = styled(Row)`
	position: relative;
`;
export const MenuItems = styled(Row)`
	svg {
		filter: ${({ theme }) => theme.misc.tealPlus40Mixin};
	}
	cursor: pointer;
	&:hover p {
		color: ${({ theme }) => theme.colors.white};
	}
	&:hover svg {
		filter: none;
	}
`;

export const ProfileText = styled(Text)`
	font-weight: 700;
	font-size: 18px;
	color: ${({ theme }) => theme.colors.white};
	:first-letter {
		text-transform: uppercase;
	}
`;

export const MenuItemText = styled(Text)`
	font-weight: 500;
	font-size: 16px;
	color: ${({ theme }) => theme.colors.tealPlus40};
	:first-letter {
		text-transform: uppercase;
	}
`;

export const ProfileSelectedIcon = styled.div<{ hidden?: boolean }>`
	display: ${({ hidden }) => (hidden ? 'none' : 'block')};
	position: absolute;
	right: 16px;
`;

export const ProfilesItems = styled.div`
`;

export const ProfileKeyContainer = styled.div`
	cursor: pointer;
`;

export const TransparentSpacer = styled.div`
	background-color: transparent;
	height: 25px;
`;
