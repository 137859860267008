import { ERROR_CODES, factory, PurchaseFailedException, PurchaseLimitReached, Request } from '@vodafoneis/sjonvarpskjarni-js-lib';
import APIClient from './APIClient';

export const purchaseProduct = async (productId: number, licenseId: number | null = null) => {
	const response = await APIClient.post(
		new Request('purchase', {
			body: {
				productId,
				licenseId,
				platform: 'OTT',
			},
		})
	);

	if (response.isSuccess()) {
		return factory(response.data);
	}

	if (response.getErrorCode() === ERROR_CODES.ERROR_PURCHASE_LIMIT_REACHED) {
		throw new PurchaseLimitReached();
	}

	throw new PurchaseFailedException(`Purchase failed for product (${productId}) and licenseId (${licenseId})`);
};
