import { Exception, Request } from '@vodafoneis/sjonvarpskjarni-js-lib';

export class BearerAuthInterceptor {
	token: string;

	constructor(token: string) {
		if (!token) throw new Exception('BearerAuthInterceptor requires auth token');
		this.token = token;
	}

	async request(request: Request) {
		if (!request.headers.Authorization) {
			request.addHeaders({
				Authorization: `Bearer ${this.token}`,
			});
		}
	}
}
