import { ImagePreset, Channel, factory, merge, AuthorizationException, MissingChannelException, MissingParameterException } from '@vodafoneis/sjonvarpskjarni-js-lib';
import PlaylistItem from './PlaylistItem';
import { PlaybackType } from '../components/Player/PlaybackType';
import { getChannel } from '../api/channel';
import { createEntitlement } from '../api/entitlement';

const { HLS, DASH } = PlaybackType;

export default class ChannelPlaylistItem extends PlaylistItem {
	channel: Channel = null;

	constructor(channel: Channel) {
		super();

		if (!channel) throw new MissingChannelException('Channel missing in constructor for ChannelPlaylistItem');

		this.channel = channel;
	}

	getId() {
		return Number(this.channel.id);
	}

	getContentTitle() {
		return this.channel.title;
	}

	getBackgroundImageUrl() {
		return this.channel.getBackdropImageUrl({ presets: [ImagePreset.BACKDROP_LARGE, ImagePreset.BACKDROP_OVERLAY] });
	}

	isLive() {
		return true;
	}

	async createEntitlement(force = false) {
		if (this.entitlement) return this.entitlement;

		this.entitlement = await createEntitlement({
			channelId: this.channel.operatorRef,
			force,
		});

		return this.entitlement;
	}

	async preparePlayback() {
		if (this.isPrepared) return;

		await super.preparePlayback();

		if (!this.channel.dashStream) {
			const channel = await getChannel(this.channel.id);

			if (channel) {
				this.channel = factory(merge(this.channel, channel));
			}
		}

		if (!this.channel.subscribed) {
			throw new AuthorizationException(`Could not prepare playback for channel ${this.getContentTitle()}, user is not subscribed`);
		}

		this.isPrepared = true;
	}

	async getUrl(playbackType: PlaybackType) {
		const { dashStream, hlsStream } = this.channel;

		if (!playbackType) {
			throw new MissingParameterException('Missing parameter in ChannelPlayingItem - getUrl', 'playbackType');
		}

		if (playbackType === DASH) {
			return PlaylistItem._prefixUrl(dashStream);
		}

		if (playbackType === HLS) {
			return PlaylistItem._prefixUrl(hlsStream);
		}

		return null;
	}

	getPositionToPlayFrom() {
		return 0;
	}
}
