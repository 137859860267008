import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState, ReactElement, useContext } from 'react';
import { useWindowSize } from 'react-use';
import { useTheme } from 'styled-components';
import { UserContext } from './UserContext';

type MobileScreensProps = {
	showMobileProfileScreen: boolean;
	showMobileMenuScreen: boolean;
	toggleMobileMenu: () => void;
	toggleProfile: () => void;
};

export const MobileScreensContext = createContext<MobileScreensProps>(null);

export const MobileScreensContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
	const [showMobileProfileScreen, setShowProfileScreen] = useState(false);
	const [showMobileMenuScreen, setShowMobileMenuScreen] = useState(false);
	const router = useRouter();
	const { width } = useWindowSize();
	const { screenLayout } = useTheme();
	const { isLoggedIn } = useContext(UserContext);

	const toggleMobileMenu = () => {
		setShowMobileMenuScreen(!showMobileMenuScreen);
	};
	const toggleProfile = () => {
		setShowProfileScreen(!showMobileProfileScreen);
	};

	useEffect(() => {
		if (width > screenLayout.tabletLandscape) {
			setShowMobileMenuScreen(false);
			setShowProfileScreen(false);
		}
	}, [width, screenLayout.tabletLandscape]);

	useEffect(() => {
		setShowMobileMenuScreen(false);
		setShowProfileScreen(false);
	}, [router.asPath]);

	useEffect(() => {
		if (!isLoggedIn) {
			setShowMobileMenuScreen(false);
			setShowProfileScreen(false);
		}
	}, [isLoggedIn]);

	const value = {
		showMobileProfileScreen,
		toggleProfile,
		showMobileMenuScreen,
		toggleMobileMenu,
	};

	return <MobileScreensContext.Provider value={value}>{children}</MobileScreensContext.Provider>;
};
