import styled from 'styled-components';
import { sharedFontStyles } from '../../../ui/core/Typography/typography.styles';
import { media } from '../../../ui/utils/media';

export const DesktopNavContainer = styled.nav`
	display: flex;
	width: 618.69px;
	height: 29.8px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 11.38px;
	${media.desktopUp} {
		width: 825px;
		height: 40px;
		gap: 16px;
	}
	${media.desktopLargeUp} {
		width: 945px;
		height: 40px;
		gap: 24px;
	}
	${media.desktopXLargeUp} {
		width: 1259.67px;
		height: 53px;
		gap: 32px;
	}
`;

export const DesktopNavItem = styled.a<{ isSelected: boolean }>`
	display: none;
	box-sizing: border-box;
	${media.tabletLandscapeUp} {
		all: unset;
		display: flex;
		align-items: center;
		padding: 0px 11.3778px;
		height: 100%;
		cursor: pointer;
		border: 2px solid transparent;
		border-radius: 6px;
		transition: border 0.3s ease-in;
		${({ theme, isSelected }) => isSelected && `border-color:  ${theme.colors.tealLight};`};
	}
	${media.desktopUp} {
		padding: 0px 16px;
	}
	${media.desktopLargeUp} {
		padding: 0px 20px;
	}
	${media.desktopXLargeUp} {
		padding: 0px 26.6667px;
		border-radius: 8px;
	}
	@media (hover) {
		&:hover,
		:focus {
			border-color: ${({ theme }) => theme.colors.tealLight};
			color: inherit; // overwriting base css that will be removed
			text-decoration: none; // overwriting base css that will be removed
		}
	}
`;

export const DesktopNavLink = styled.p`
	all: unset;
	${sharedFontStyles};
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	${media.desktopUp} {
		font-size: 18px;
		line-height: 22px;
	}
	${media.desktopXLargeUp} {
		font-size: 24px;
		line-height: 29px;
	}
`;

export const MobileNavContainer = styled.nav`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	gap: 2px;
	flex-direction: column;
	height: 100%;
	width: 100%;
	z-index: 8;
	background: ${({ theme }) => theme.misc.bgGradient};
	padding: ${({ theme }) => theme.headerHeight.mobile}px 0 16px;
	${media.tabletPortraitUp} {
		padding: ${({ theme }) => theme.headerHeight.tabletPortrait}px 0 16px;
	}
`;

export const MobileNavItem = styled.div<{ isSelected: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 358px;
	height: 52px;
	padding: 15px 20px;
	border: 2px solid transparent;
	border-radius: 6px;
	transition: border 0.3s ease-in;
	cursor: pointer;
	@media (hover) {
		&:hover,
		:focus {
			border-color: ${({ theme }) => theme.colors.tealLight};
		}
	}
	${({ theme, isSelected }) =>
		isSelected &&
		`
			border-color: ${theme.colors.tealLight};
	`};
`;

export const MobileNavText = styled.a`
	all: initial;
	${sharedFontStyles};
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	@media (hover) {
		&:hover,
		:focus {
			color: inherit; // overwriting base css that will be removed
			text-decoration: none; // overwriting base css that will be removed
		}
	}
`;
