import styled from 'styled-components';
import { Button } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { PRIMARY } from '../styles/colors';
import { buttonReset } from '../styles/buttonReset';

export const ButtonLink = styled(Button)`
	${buttonReset};
	color: ${PRIMARY};

	@media (hover) {
		&:hover {
			color: #fff;
		}
	}
`;
