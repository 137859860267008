import styled from 'styled-components';

export const Divider = styled.div`
	height: 1px;
	background: rgba(255, 255, 255, 0.1);
	background-blend-mode: soft-light;
`;

export const TempSpacerForOldDesign = styled.div`
	display: flex;
	height: 1px;
	margin-bottom: 40px;
`;
