import styled, { css } from 'styled-components';
import { media } from '../../utils/media';
import { SharedTypographyTypes } from './types';

export const sharedFontStyles = css<SharedTypographyTypes>`
	font-family: 'Albert Sans';
	font-style: normal;
	letter-spacing: -0.01em;
	color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.white)};
	${({ truncate }) =>
		truncate &&
		`
		 	overflow: hidden;
    	text-overflow: ellipsis;
    	white-space: nowrap;
	`}
`;

export const H1 = styled.h1`
	${sharedFontStyles};
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	letter-spacing: -0.02em;
	font-feature-settings: 'ss02' on;
	text-shadow: ${({ theme }) => theme.misc.h1TextShadow};
	${media.desktopLargeUp} {
		font-size: 80px;
		line-height: 80px;
	}
`;

export const H2 = styled.h2`
	${sharedFontStyles};
	font-weight: 700;
	font-size: 28px;
	line-height: 30px;
	${media.desktopUp} {
		font-size: 30px;
		line-height: 32px;
	}
	${media.desktopLargeUp} {
		font-size: 52px;
		line-height: 52px;
	}
`;

export const H3 = styled.h3`
	${sharedFontStyles};
	font-weight: 700;
	font-size: 24px;
	line-height: 30px;
	${media.desktopLargeUp} {
		font-size: 42px;
		line-height: 42px;
	}
`;

export const H4 = styled.h4`
	${sharedFontStyles};
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	${media.desktopLargeUp} {
		font-size: 36px;
		line-height: 36px;
	}
`;

export const H5 = styled.h5`
	${sharedFontStyles};
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	${media.desktopLargeUp} {
		font-size: 28px;
		line-height: 28px;
	}
`;

export const H6 = styled.h6`
	${sharedFontStyles};
	font-weight: 700;
	text-shadow: ${({ theme }) => theme.misc.h6TextShadow};
	font-size: 16px;
	line-height: 18px;
	${media.desktopLargeUp} {
		font-size: 22px;
		line-height: 22px;
	}
`;

export const IntroText = styled.p`
	${sharedFontStyles};
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	font-feature-settings: 'ss02' on;
	text-shadow: ${({ theme }) => theme.misc.introTextShadow};
	${media.desktopUp} {
		font-size: 24px;
		line-height: 30px;
	}
	${media.desktopLargeUp} {
		line-height: 32px;
	}
	${media.desktopXLargeUp} {
		font-size: 32px;
		line-height: 43px;
		margin-bottom: 32px;
		text-shadow: 0px 1.33333px 5.33333px rgba(0, 0, 0, 0.25);
	}
`;

export const BodyText = styled.p`
	${sharedFontStyles};
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	${media.desktopLargeUp} {
		font-size: 18px;
		line-height: 26px;
	}
`;

export const BodyTextSmall = styled.p`
	${sharedFontStyles};
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-shadow: ${({ theme }) => theme.misc.bodySmallTextShadow};
	${media.desktopLargeUp} {
		font-size: 16px;
		line-height: 22px;
	}
`;

export const BodyTextTiny = styled.p`
	${sharedFontStyles};
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	${media.desktopLargeUp} {
		font-size: 14px;
		line-height: 17px;
	}
`;

export const TagTextSmall = styled.p`
	${sharedFontStyles};
	font-size: 16px;
	font-weight: 800;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
`;

export const TinyLabel = styled.p`
	${sharedFontStyles};
	font-weight: 800;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.white};
`;

export const NormalLabel = styled.p`
	${sharedFontStyles};
	font-weight: 800;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.white};
	${media.desktopUp} {
		font-size: 16px;
		line-height: 19px;
	}
	${media.desktopLargeUp} {
		font-size: 21px;
		line-height: 25px;
	}
`;

export const WarningFixed = styled.h4`
	${sharedFontStyles};
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	margin: 0;
`;

export const WarningText = styled.h3`
	${sharedFontStyles};
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	margin: 0;
`;
