import React, { useState } from 'react';
import Link from 'next/link';
import { LinkText, MenuItems, MenuPanelContainer } from './Footer.styles';
import { MenuHead } from './MenuHead';
import FooterLink from '../../models/strapi/FooterLink';

type MenuPanelProps = {
	headTitle : string,
	links : FooterLink[]
};

export const MenuPanel: React.FC<MenuPanelProps> = ({ headTitle, links }) => {
	const [expanded, setExpanded] = useState(false);

	const onClickExpand = () => {
		setExpanded(!expanded);
	};

	return (
		<MenuPanelContainer>
			<MenuHead title={headTitle} isOn={expanded} onClick={onClickExpand} />
			<MenuItems expanded={expanded}>
				{links.map((link, index) => {
					return (
						// eslint-disable-next-line react/no-array-index-key
						<Link key={index} href={link.url}>
							<LinkText href={link.url} target={link?.newTab ? '_blank' : '_self'} rel="noopener noreferrer" isDisabled={!link.url}>
								{link.text}
							</LinkText>
						</Link>
					);
				})}
			</MenuItems>
		</MenuPanelContainer>
	);
};
