import { screenLayout } from '../theme/layout';

const mediaQuery = (maxWidth: number) => `@media only screen and (max-width: ${maxWidth}px)`;
const mediaQueryUp = (minWidth: number) => `@media only screen and (min-width: ${minWidth}px)`;

export const media = {
	custom: mediaQuery,
	customUp: (size: number) => mediaQueryUp(size),
	mobile: mediaQuery(screenLayout.tabletPortrait),
	mobileUp: mediaQueryUp(screenLayout.mobile),
	mobileSmall: mediaQuery(screenLayout.mobile),
	mobileSmallUp: mediaQueryUp(screenLayout.mobileSmall),
	tabletPortrait: mediaQuery(screenLayout.tabletLandscape),
	tabletPortraitUp: mediaQueryUp(screenLayout.tabletPortrait),
	tabletLandscape: mediaQuery(screenLayout.desktop),
	tabletLandscapeUp: mediaQueryUp(screenLayout.tabletLandscape),
	desktop: mediaQuery(screenLayout.desktopLarge),
	desktopUp: mediaQueryUp(screenLayout.desktop),
	desktopLarge: mediaQuery(screenLayout.desktopXLarge),
	desktopLargeUp: mediaQueryUp(screenLayout.desktopLarge),
	desktopXLargeUp: mediaQueryUp(screenLayout.desktopXLarge),
	oldLg: mediaQuery(screenLayout.oldLg),
	oldLgUp: mediaQueryUp(screenLayout.oldLg),
};

export type ThemeBreakpoints = typeof screenLayout;
type HiddenProp = keyof ThemeBreakpoints | undefined;

export const resolveHiddenBreakpoints = (above: HiddenProp, below: HiddenProp) => {
	if (above) {
		return `${mediaQueryUp(screenLayout[above])} { display: none; }`;
	}
	if (below) {
		return `${mediaQuery(screenLayout[below])} { display: none; }`;
	}
	return null;
};
