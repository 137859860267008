import styled, { css } from 'styled-components';
import { ICON_TYPE } from '../../../components/IconComponent/IconComponent';
import { ThemeColorProp } from '../../theme/theme';
import { media } from '../../utils/media';

const defaultStyles = css`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	backdrop-filter: blur(6px);
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.misc.rightOverLayGradient};
	background-color: ${({ theme }) => theme.colors.btnPrimary};
	transition: background-color 0.4s ease-in;
`;

export const StyledLinkBtn = styled.a`
	${defaultStyles}
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	height: 28.44px;
	font-size: 14px;
	padding: 13px 20px;
	font-weight: 600;
	font-size: 18px;
	white-space: nowrap;
	${media.tabletLandscape} {
		height: 28.44px;
	}
	${media.desktopUp} {
		height: 40px;
		font-size: 18px;
	}
	${media.desktopXLargeUp} {
		height: 53.3px;
		font-size: 24px;
	}

	&:hover,
	:focus {
		outline: none;
		text-decoration: none;
		background-color: ${({ theme }) => theme.colors.btnPrimaryLight};
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const StyledInstructionsBtn = styled.a`
	${defaultStyles}
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	line-height: 22px;
	padding: 13px 20px;
	font-weight: 700;
	font-size: 18px;
	color: ${({ theme }) => theme.colors.tealPlus20};
	background: transparent;
	background-color: transparent;
	border: 1px solid rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(6px);
	/* Note: backdrop-filter has minimal browser support */
	border-radius: 6px;

	&:hover,
	:focus {
		outline: none;
		text-decoration: none;
		background-color: rgba(255, 255, 255, 0.06);
		border: 1px solid rgba(255, 255, 255, 0.04);
		color: ${({ theme }) => theme.colors.white};
	}

	${media.desktopUp} {
		padding: 13px 16px;
		width: 123px;
		height: 50px;
		font-size: 20px;
		line-height: 24px;
	}
	${media.desktopXLargeUp} {
		padding: 13px 16px;
		width: 163px;
		height: 66.67px;
		font-size: 26.6667px;
		line-height: 32px;
		gap: 17.3px;
	}
`;

type WatchIconButtonProps = {
	bgColor: ThemeColorProp;
	hoverBgColor: ThemeColorProp;
};

export const StyledMainBuyLink = styled.a<WatchIconButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 13px;
	height: 44px;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: -0.01em;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.misc.rightOverLayGradient};
	background-color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.colors.btnPrimary)};
	transition: background-color 0.4s ease-in;
	padding: 10px 16px;
	border-radius: 4px;
	&:hover,
	:focus {
		outline: none;
		text-decoration: none;
		color: ${({ theme }) => theme.colors.white};
		background-color: ${({ theme, hoverBgColor }) => (hoverBgColor ? theme.colors[hoverBgColor] : theme.colors.btnPrimaryLight)};
	}
	${media.desktopUp} {
		padding: 13px 16px;
		height: 50px;
		font-size: 20px;
		line-height: 24px;
	}
	${media.desktopXLargeUp} {
		padding: 13px 16px;
		height: 66.67px;
		font-size: 26.6667px;
		line-height: 32px;
		gap: 17.3px;
	}
`;

export const StyledActionBtn = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px;
	width: 44px;
	height: 44px;
	background: linear-gradient(260.04deg, rgba(255, 255, 255, 0.1) -0.21%, rgba(255, 255, 255, 0) 104.36%), rgba(255, 255, 255, 0.15);
	backdrop-filter: blur(12px);
	border-radius: 4px;
	transition: background-color 0.4s ease-in;
	&:hover,
	:focus {
		background-color: rgba(255, 255, 255, 0.4);
	}
	${media.desktopUp} {
		padding: 13px 16px;
		width: 56px;
		height: 50px;
	}
	${media.desktopXLargeUp} {
		width: 74.67px;
		height: 66.67px;
		backdrop-filter: blur(16px);
	}
`;

type ButtonProps = {
	bgColor?: ThemeColorProp;
	bgHoverColor?: ThemeColorProp;
	iconType?: ICON_TYPE;
	borderColor?: ThemeColorProp;
	textColor?: ThemeColorProp;
	fade?: boolean;
};

export const StyledButton = styled.button<ButtonProps>`
	${defaultStyles};
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 13px;
	min-width: fit-content;
	height: 44px;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: -0.01em;
	color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : theme.colors.white)};
	background: ${({ theme, fade }) => (fade ? theme.misc.rightOverLayGradient : theme.colors.none)};
	background-color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.colors.btnPrimary)};
	border: 1px solid ${({ theme, borderColor }) => (borderColor ? theme.colors[borderColor] : theme.colors.none)};
	transition: background-color 0.4s ease-in;
	padding: 10px 16px;
	border-radius: 4px;
	&:hover,
	:focus {
		background-color: ${({ theme, bgHoverColor }) => (bgHoverColor ? theme.colors[bgHoverColor] : theme.colors.btnPrimaryLight)};
	}
	${media.desktopUp} {
		padding: 13px 16px;
		height: 50px;
		font-size: 20px;
		line-height: 24px;
	}
	${media.desktopXLargeUp} {
		padding: 13px 16px;
		height: 66.6px;
		font-size: 26.6px;
		line-height: 32px;
		gap: 17.3px;
	}
`;

type StrapiButtonProps = {
	bgColor: ThemeColorProp;
	hoverBgColor: ThemeColorProp;
	textColor?: ThemeColorProp;
	isHyper?: boolean;
};

export const StrapiButtonLink = styled.a<StrapiButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 13px;
	height: 44px;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: -0.01em;
	color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : theme.colors.white)};
	background-color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.colors.teal)};
	transition: background-color 0.4s ease-in;
	padding: ${({ isHyper }) => (isHyper ? '10px 0px' : '10px 16px')};
	border-radius: 4px;
	svg {
		transform: translateX(0px);
		transition: 0.5s ease;
	}

	&:hover,
	:focus {
		outline: none;
		text-decoration: none;
		color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : theme.colors.white)};
		background-color: ${({ theme, hoverBgColor }) => (hoverBgColor ? theme.colors[hoverBgColor] : theme.colors.transparent)};

		svg {
			transform: translateX(5px);
			transition: 0.5s ease;
		}
	}
	${media.desktopUp} {
		padding: ${({ isHyper }) => (isHyper ? '13px 0px' : '13px 16px')};
		height: 50px;
		font-size: 20px;
		line-height: 24px;
	}
	${media.desktopXLargeUp} {
		padding: ${({ isHyper }) => (isHyper ? '13px 0px' : '13px 16px')};
		height: 66.6px;
		font-size: 26.6px;
		line-height: 32px;
		gap: 17.3px;
	}
`;
