import {
	Entitlement,
	AccountBlockingException,
	AuthenticationException,
	AuthorizationException,
	CouldNotCreateEntitlementException,
	EntitlementExpiredException,
	EntitlementLimitReachedException,
	ERROR_CODES,
	GeoBlockingException,
} from '@vodafoneis/sjonvarpskjarni-js-lib';
import Request from '../utils/Request';
import APIClient from './APIClient';
import Device from '../models/Device';

const { ERROR_ACCOUNT_BLOCKING, ERROR_GEO_BLOCKING, ERROR_ENTITLEMENT_LIMIT_REACHED, ERROR_NOT_SUBSCRIBED } = ERROR_CODES;

type CreateEntitlementParamsContent = {
	movieId: number;
	licenseId: number;
	force: boolean;
};

type CreateEntitlementParamsChannel = {
	channelId: string;
	force: boolean;
};

type CreateEntitlementParams = CreateEntitlementParamsContent | CreateEntitlementParamsChannel;

export const createEntitlement = async (data: CreateEntitlementParams) => {
	const device = Device.getCurrentDevice();

	if (!device) {
		throw new AuthenticationException();
	}

	const request = new Request('entitlement', {
		body: {
			deviceId: device.id,
		},
	});

	request.addToBody(data as any);

	const response = await APIClient.post(request);

	if (response.isSuccess() && response.data) {
		return new Entitlement(response.data);
	}

	if (response.getErrorCode() === ERROR_ACCOUNT_BLOCKING) {
		throw new AccountBlockingException();
	} else if (response.getErrorCode() === ERROR_GEO_BLOCKING) {
		throw new GeoBlockingException();
	} else if (response.getErrorCode() === ERROR_ENTITLEMENT_LIMIT_REACHED) {
		throw new EntitlementLimitReachedException();
	} else if (response.getErrorCode() === ERROR_NOT_SUBSCRIBED) {
		throw new AuthorizationException('Entitlement could not be created, user is not subscribed to content');
	} else {
		throw new CouldNotCreateEntitlementException();
	}
};

export const updateEntitlement = async (id) => {
	const request = new Request('entitlement/$$id$$', {
		pathVariables: {
			id,
		},
	});

	const response = await APIClient.put(request);

	if (response.isSuccess() && response.data) {
		return response.data;
	}

	if (response.statusCode === 404) {
		throw new EntitlementExpiredException();
	} else {
		// Ignore all other errors
	}

	return null;
};

export const deleteEntitlement = async (id) => {
	const request = new Request('entitlement/$$id$$', {
		pathVariables: {
			id,
		},
	});

	await APIClient.del(request);
};
