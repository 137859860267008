import React, { createContext, useCallback, useEffect, useState, useMemo, useContext, forwardRef, useRef, ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useEffectOnce, useLocalStorage, usePrevious } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import 'moment/locale/is';
import type { Event } from '@bugsnag/js';
import { ClientResponse, QueryContextProvider, Profile } from '@vodafoneis/sjonvarpskjarni-js-lib';
import User from '../models/User';
import Device from '../models/Device';
import { track } from '../utils/Analytics';
import { Client as BugsnagClient } from '../utils/Bugsnag';
import APIClient from '../api/APIClient';
import { BearerAuthInterceptor } from '../utils/BearerAuthInterceptor';
import { AnalyticsEvent } from '../utils/AnalyticsEvent';
import { DEFAULT_LANGUAGE } from '../config/constants';
import ProfileUtils from '../utils/ProfileUtils';

type UserContextProps = {
	user: User;
	isLoggedIn: boolean;
	haveAskedForProfile: boolean;
	device: Device;
	isSelectingProfile: () => boolean;
	isLoadingProfile: () => boolean;
	login: ({ username, password }: { username: string; password: string }) => void;
	logout: () => void;
	requestAuthentication: (redirectUrl?: string) => void;
	verifyPin: (pin: string) => Promise<ClientResponse<any>>;
	verifyPassword: (username: string, password: string) => Promise<ClientResponse<any>>;
	language: string;
	setLanguage: (language: string) => void;
	setProfile: (userAccess: User, profile: Profile) => void;
	getProfileName: () => string;
	setHaveAskedForProfile: () => void;
};

export const UserContext = createContext<Partial<UserContextProps>>({});

export const UserContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
	const router = useRouter();
	const apolloClient = useApolloClient();
	const { i18n } = useTranslation();
	const [persistedLanguage, setPersistedLanguage] = useLocalStorage('settings.language', DEFAULT_LANGUAGE);
	const { t } = useTranslation();
	const [user, setUser] = useState(User.retrieveUser());
	const [selectedProfile, setSelectedProfile] = useState<Profile>(null);
	const [isLoadingProfiles, setIsLoadingProfiles] = useState<boolean>(false);
	const [device, setDevice] = useState(Device.getCurrentDevice());
	const [language, setLanguage] = useState(persistedLanguage);
	const prevLanguage = usePrevious(language);
	const isLoggingIn = useRef(false);
	const isLoggedIn = useMemo(() => user && !user.isAnonymous(), [user]);
	const isSelectingProfile = useCallback(() => router?.isReady && router?.pathname === '/profiles', [router]);
	const isLoadingProfile = useCallback(() => isLoadingProfiles, [isLoadingProfiles]);
	const accessToken = useMemo(() => user?.accessToken ?? null, [user]);
	const haveAskedForProfile = useMemo(() => user?.haveAskedForProfile ?? null, [user]);

	const anonymousLogin = useCallback(async () => {
		if (isLoggingIn.current) return;

		isLoggingIn.current = true;

		const anonymousUser = await User.login();

		setUser(anonymousUser);
		setDevice(Device.getCurrentDevice());

		isLoggingIn.current = false;
	}, []);

	const login = useCallback(async ({ username, password }) => {
		if (isLoggingIn.current) return;

		try {
			isLoggingIn.current = true;

			const loggedInUser = await User.login({ username, password });

			setUser(loggedInUser);
			setDevice(Device.getCurrentDevice());

			track(AnalyticsEvent.LOGIN, { username });

			isLoggingIn.current = false;
		} catch (exception) {
			isLoggingIn.current = false;

			throw exception;
		}
	}, []);

	const setProfile = useCallback(
		async (userAccess, profile) => {
			try {
				setIsLoadingProfiles(true);
				const userRef = User.retrieveUser();
				userRef.selectedProfile = profile;
				userRef.accessToken = userAccess.accessToken;
				userRef.profileId = profile.id;
				userRef.haveAskedForProfile = true;
				setSelectedProfile(profile);
				setUser(userRef);
				User.saveUser(userRef);
				await apolloClient.clearStore();
			} catch (error) {
				BugsnagClient.notify(error);
			} finally {
				setIsLoadingProfiles(false);
			}
		},
		[apolloClient]
	);

	const setHaveAskedForProfile = useCallback(async () => {
		try {
			setIsLoadingProfiles(true);
			const userRef = User.retrieveUser();
			userRef.haveAskedForProfile = true;
			setUser(userRef);
			User.saveUser(userRef);
		} catch (error) {
			BugsnagClient.notify(error);
		} finally {
			setIsLoadingProfiles(false);
		}
	}, []);

	const logout = useCallback(async () => {
		User.clearUser();
		Device.setCurrentDevice(null);
		setUser(null);
		setSelectedProfile(null);
		setDevice(null);

		try {
			await apolloClient.resetStore();
		} catch (error) {} // Ignore error
	}, [apolloClient]);

	const requestAuthentication = useCallback(
		(redirectUrl?: string) => {
			if (redirectUrl) {
				router.push(`/login?r=${redirectUrl}`);
			} else {
				router.push('/login');
			}
		},
		[router]
	);

	const verifyPin = useCallback((pin: string) => User.verifyPin(pin), []);

	const verifyPassword = useCallback((username: string, password: string) => User.verifyPassword(username, password), []);

	// Anonymously login the user if not logged in
	useEffect(() => {
		if (!user) {
			anonymousLogin();
		}
	}, [anonymousLogin, user]);

	// Update device
	useEffect(() => {
		if (accessToken && device) {
			device.update(accessToken);
		}
	}, [accessToken, device]);

	// Add unauthorized interceptor to API client
	useEffectOnce(() => {
		const interceptor = {
			response: (response: ClientResponse<any>) => {
				if (response.statusCode === 401) {
					logout();
					requestAuthentication();
				}
			},
		};

		APIClient.addInterceptor(interceptor);

		return () => {
			APIClient.removeInterceptor(interceptor);
		};
	});

	// Add authentication interceptor to API client
	useEffect(() => {
		let bearerAuthInterceptor: BearerAuthInterceptor = null;

		if (accessToken) {
			bearerAuthInterceptor = new BearerAuthInterceptor(accessToken);
			APIClient.addInterceptor(bearerAuthInterceptor);
		}

		return () => {
			if (bearerAuthInterceptor) {
				APIClient.removeInterceptor(bearerAuthInterceptor);
			}
		};
	}, [accessToken]);

	// Update Bugsnag with user information.
	useEffect(() => {
		if (user) {
			const { id } = user;
			BugsnagClient.setUser(id);
		} else {
			BugsnagClient.setUser();
		}

		return () => {
			BugsnagClient.setUser();
		};
	}, [user]);

	// Update Bugsnag with device information
	useEffect(() => {
		const callback = (event: Event) => {
			if (device) {
				// eslint-disable-next-line no-param-reassign
				event.device.id = device.id;
			}
		};

		BugsnagClient.addOnError(callback);

		return () => {
			BugsnagClient.removeOnError(callback);
		};
	}, [device]);

	useEffect(() => {
		(async () => {
			if (prevLanguage !== language) {
				setPersistedLanguage(language);

				if (i18n.language !== language) {
					i18n.changeLanguage(language);
				}

				if (moment.locale() !== language) {
					moment.locale(language);
				}

				if (prevLanguage) {
					try {
						await apolloClient.resetStore();
					} catch (error) {} // Ignore error
				}
			}
		})();
	}, [apolloClient, i18n, language, prevLanguage, setPersistedLanguage]);

	const redirectToProfiles = useCallback(() => {
		if (!isSelectingProfile() && router.isReady) {
			router.push('/profiles');
		}
	}, [router, isSelectingProfile]);

	// Navigate the user to profile select if no profile is selected.
	useEffect(() => {
		if (isLoggedIn && !user?.selectedProfile && !user?.haveAskedForProfile && !ProfileUtils.isPageAllowedWithoutProfile(router.asPath)) {
			redirectToProfiles();
		}
		if (ProfileUtils.isPageBannedForProfile(user?.selectedProfile, router.asPath)) {
			router.push('/genre/children');
		}
	}, [user, isLoggedIn, redirectToProfiles, router]);

	const getProfileName = useCallback(() => {
		if (!user?.selectedProfile || user?.selectedProfile?.name.trim() === '' || user?.selectedProfile?.name === 'Heima') {
			return t('Profiles.Home');
		}
		return user?.selectedProfile?.name;
	}, [t, user]);

	const value = useMemo(
		() => ({
			user,
			isLoggedIn,
			haveAskedForProfile,
			isSelectingProfile,
			isLoadingProfile,
			device,
			login,
			logout,
			requestAuthentication,
			verifyPin,
			verifyPassword,
			language,
			setLanguage,
			setProfile,
			setHaveAskedForProfile,
			getProfileName,
		}),
		[
			user,
			isLoggedIn,
			haveAskedForProfile,
			isSelectingProfile,
			isLoadingProfile,
			device,
			login,
			logout,
			requestAuthentication,
			verifyPin,
			verifyPassword,
			language,
			setProfile,
			getProfileName,
			setHaveAskedForProfile,
		]
	);
	return (
		<UserContext.Provider value={value}>
			{/*
// @ts-ignore */}
			<QueryContextProvider accessToken={user?.accessToken ?? null} language={language}>
				{children}
			</QueryContextProvider>
		</UserContext.Provider>
	);
};

export const withUserContext = <P extends object>(Component: React.ComponentType<P>) =>
	forwardRef((props, ref) => {
		const userContext = useContext(UserContext);

		return <Component ref={ref} {...(props as P)} {...userContext} />;
	});
