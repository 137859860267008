import React, { useState, useEffect, createContext, useReducer, Dispatch, useMemo, useCallback, ReactElement } from 'react';
import { useRouter } from 'next/router';
import SecurityUtility from '../utils/SecurityUtility';
import { REGISTRATION_ACTIONS } from '../scenes/RegisterScene/RegistrationConstants';
import { LoginBlockedReason } from '../components/LoginBlocked';

const initialState = {
	loading: false,
	errorMessage: null,
	username: null,
	password: null,
	phone: null,
	ssn: null,
	code: null,
	gdprPreConfirmed: false, // If the gdpr is already confirmed by user (we don't prompt again)
	confirmGdpr: false, // If the user confirmed gdpr now
	sessionId: null,
	loginBlockedReason: null,
	step: 1,
};

type RegisterContextProps = {
	loading: boolean;
	setLoading: (loading: boolean) => void;
	errorMessage: string | null;
	setErrorMessage: (errorMessage: string) => void;
	username: string | null;
	setUsername: (username: string) => void;
	password: string | null;
	setPassword: (password: string) => void;
	phone: string | null;
	setPhone: (phone: string) => void;
	ssn: string | null;
	setSsn: (ssn: string) => void;
	code: string | null;
	setCode: (code: string) => void;
	gdprPreConfirmed: boolean;
	setGdprPreConfirmed: (gdprPreConfirmed: boolean) => void;
	confirmGdpr: boolean;
	setConfirmGdpr: (confirmGdpr: boolean) => void;
	sessionId: string | null;
	setSessionId: (sessionId: string) => void;
	loginBlockedReason: LoginBlockedReason | null;
	setLoginBlockedReason: (loginBlockedReason: LoginBlockedReason) => void;
	step: number;
	dispatch: Dispatch<any>;
	reset: () => void;
};

export const RegisterContext = createContext<Partial<RegisterContextProps>>(initialState);

const { RESET, PREV_STEP, NEXT_STEP, SET_STEP, LOGIN, SUCCESS } = REGISTRATION_ACTIONS;

function reducer(state, action) {
	const { type, step: stepToSet } = action;
	const { step, router } = state;
	switch (type) {
		case RESET:
			return { ...state, step: 1 };
		case PREV_STEP:
			return { ...state, step: step - 1 };
		case NEXT_STEP:
			return { ...state, step: step + 1 };
		case SET_STEP:
			return { ...state, step: stepToSet || 1 };
		case LOGIN:
			router.replace('/login');
			return state;
		case SUCCESS:
			router.replace('/');
			return state;
		default:
			return state;
	}
}

export const RegisterContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
	const [loading, setLoading] = useState(initialState.loading);
	const [errorMessage, setErrorMessage] = useState(initialState.errorMessage);
	const [username, setUsername] = useState(initialState.username);
	const [password, setPassword] = useState(initialState.password);
	const [phone, setPhone] = useState(initialState.phone);
	const [ssn, setSsn] = useState(initialState.ssn);
	const [code, setCode] = useState(initialState.code);
	const [gdprPreConfirmed, setGdprPreConfirmed] = useState(initialState.gdprPreConfirmed);
	const [confirmGdpr, setConfirmGdpr] = useState(initialState.confirmGdpr);
	const [sessionId, setSessionId] = useState(initialState.sessionId);
	const [loginBlockedReason, setLoginBlockedReason] = useState(initialState.loginBlockedReason);

	const reset = useCallback(() => {
		setLoading(initialState.loading);
		setErrorMessage(initialState.errorMessage);
		setUsername(initialState.username);
		setPassword(initialState.password);
		setPhone(initialState.phone);
		setSsn(initialState.ssn);
		setCode(initialState.code);
		setGdprPreConfirmed(initialState.gdprPreConfirmed);
		setConfirmGdpr(initialState.confirmGdpr);
		setSessionId(initialState.sessionId);
	}, []);

	const router = useRouter();
	const [{ step }, dispatch] = useReducer(reducer, { step: initialState.step, router });

	useEffect(() => {
		const { NO_LOCAL_STORAGE } = LoginBlockedReason;

		if (!SecurityUtility.isLocalStorageAllowed()) {
			setLoginBlockedReason(NO_LOCAL_STORAGE);
		}
	}, [setLoginBlockedReason]);

	const value = useMemo(() => {
		return {
			loading,
			setLoading,
			errorMessage,
			setErrorMessage,
			username,
			setUsername,
			password,
			setPassword,
			phone,
			setPhone,
			ssn,
			setSsn,
			code,
			setCode,
			gdprPreConfirmed,
			setGdprPreConfirmed,
			confirmGdpr,
			setConfirmGdpr,
			sessionId,
			setSessionId,
			loginBlockedReason,
			step,
			dispatch,
			reset,
		};
	}, [loading, errorMessage, username, password, phone, ssn, code, gdprPreConfirmed, confirmGdpr, sessionId, loginBlockedReason, step, reset]);

	return <RegisterContext.Provider value={value}>{children}</RegisterContext.Provider>;
};
