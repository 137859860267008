/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { CHANNEL_QUERY, factory } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { client } from './ApolloClient';
import User from '../models/User';

export const getChannel = async (channelId: number) => {
	const user = User.retrieveUser();

	const { data } = await client.query({
		query: CHANNEL_QUERY,
		variables: { channelId },
		fetchPolicy: 'network-only',
		...(user
			? {
					context: {
						headers: {
							Authorization: `Bearer ${user.accessToken}`,
						},
					},
			  }
			: {}),
	});

	if (data?.channel) {
		return factory(data.channel);
	}

	return null;
};
