import { UUID, Entitlement } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { PlaybackType } from '../components/Player/PlaybackType';
import Device from './Device';

const PLAYBACK_URL_PREFIX = 'https://director.tv.c.is';

export default class PlaylistItem {
	playbackSessionId?: string;

	entitlement: Entitlement;

	isPrepared: boolean = false;

	lastKnownPosition = 0;

	static _prefixUrl(url: string): string {
		return url ? PLAYBACK_URL_PREFIX + url : null;
	}

	getPlaybackSessionId() {
		if (!this.playbackSessionId) {
			const parts = [this.getId(), Device.getDeviceId(), new Date().toISOString()];

			this.playbackSessionId = UUID.fromString(parts.join('_'));
		}

		return this.playbackSessionId;
	}

	getId(): number {
		return null;
	}

	getContentTitle(): string {
		return null;
	}

	getBackgroundImageUrl(): string {
		return null;
	}

	isLive(): boolean {
		return false;
	}

	async createEntitlement(force = false): Promise<Entitlement> {
		return null;
	}

	requiresRental() {
		return false;
	}

	async preparePlayback() {}

	async getUrl(playbackType: PlaybackType): Promise<string> {
		return null;
	}

	getPositionToPlayFrom(): number {
		return 0;
	}

	async savePosition(position: number) {
		this.lastKnownPosition = position;
	}

	getVastUrl(): string {
		return null;
	}
}
