import React, { FC } from 'react';
import { LoadingBlur, LoadingRing } from './EditProfile.styles';

type BlurProps = {
	show: boolean;
};

export const LoadingBlurComponent: FC<BlurProps> = ({ show }) => {
	return (
		<LoadingBlur show={show}>
			<LoadingRing />
		</LoadingBlur>
	);
};
