import Bowser from 'bowser';
import Fingerprint from 'fingerprintjs2';
import { Request, UUID, UnsupportedDeviceException } from '@vodafoneis/sjonvarpskjarni-js-lib';
import APIClient from '../api/APIClient';
import { UNSUPPORTED_BROWSERS } from '../config/constants';

const LOCAL_STORAGE_KEY = 'vfi_device_id2';

export default class Device {
	id?: string;
	name?: string;

	static currentDevice?: Device | null = null;

	constructor(data: object) {
		Object.assign(this, data);
	}

	static async getOrGenerateDeviceId() {
		try {
			let deviceId = Device.getDeviceId();

			if (!deviceId) {
				deviceId = await Device.generateDeviceId();
				localStorage.setItem(LOCAL_STORAGE_KEY, deviceId);
			}

			return deviceId;
		} catch (exception) {}

		return null;
	}

	static getDeviceId() {
		try {
			return localStorage.getItem(LOCAL_STORAGE_KEY);
		} catch (exception) {}

		return null;
	}

	static async generateDeviceId() {
		const FingerprintComponents = await Fingerprint.getPromise();
		const seeds = FingerprintComponents.map((component) => component.value);
		seeds.push(new Date().toDateString());
		seeds.push(new Date().toTimeString());
		return UUID.fromString(seeds.join(''));
	}

	static detectBrowserInfo() {
		return Bowser.getParser(window.navigator.userAgent);
	}

	static detectBrowserType() {
		return Device.detectBrowserInfo().getBrowserName().replace(/\s+/g, '_').toUpperCase() || null;
	}

	static checkBrowserSupport() {
		const browserType = Device.detectBrowserType();

		if (!browserType || UNSUPPORTED_BROWSERS.includes(browserType)) {
			throw new UnsupportedDeviceException(`Browser '${browserType}' is not supported`);
		}
	}

	static setCurrentDevice(device) {
		try {
			if (device) {
				window.localStorage.setItem('device', JSON.stringify(device));
			} else {
				window.localStorage.removeItem('device');
			}

			Device.currentDevice = device;
		} catch (exception) {}
	}

	static getCurrentDevice(): Device {
		let device = Device.currentDevice;

		if (device === null) {
			try {
				const deviceData = window.localStorage.getItem('device');
				if (deviceData) {
					device = new Device(JSON.parse(deviceData));
					Device.setCurrentDevice(device);
				}
			} catch (exception) {}
		}

		return device;
	}

	update(accessToken) {
		return APIClient.put(
			new Request('device/$$deviceId$$', {
				pathVariables: {
					deviceId: this.id,
				},
				body: {
					name: this.name,
				},
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			})
		);
	}

	async del() {
		const response = await APIClient.del(
			new Request('device/$$deviceId$$', {
				pathVariables: {
					deviceId: this.id,
				},
			})
		);

		Device.setCurrentDevice(null);

		return response;
	}
}
