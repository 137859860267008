import styled from 'styled-components';
import { media } from '../../../ui/utils/media';

type MenuIconProps = {
	open?: boolean;
};

export const MenuIconWrapper = styled.div`
	position: relative;
	width: 48px;
	height: 48px;
	cursor: pointer;
	${media.tabletLandscapeUp} {
		display: none;
	}
`;

export const MenuIconContainer = styled.div<MenuIconProps>`
	position: absolute;
	top: 9px;
	left: 9px;
	width: 30px;
	height: 30px;
	transition: transform 0.2s ease-in-out;
`;

export const MenuIconContainerTop = styled(MenuIconContainer)`
	transform: ${({ open }) => (open ? 'rotate(45deg)' : 'none')};
`;

export const MenuIconContainerBottom = styled(MenuIconContainer)`
	transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'none')};
`;

export const Line = styled.div<MenuIconProps>`
	position: absolute;
	width: 17px;
	height: 1px;
	background: #fff;
	border-radius: 0.5px;
	left: 7px;
`;

export const LineTop = styled(Line)`
	top: 14px;
	transform: ${({ open }) => (!open ? 'translateY(-3px)' : 'none')};
`;

export const LineBottom = styled(Line)`
	bottom: 14px;
	transform: ${({ open }) => (!open ? 'translateY(3px)' : 'none')};
`;
