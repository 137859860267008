import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../Header/Header';
import { FooterComponent } from '../Footer/Footer';
import { MobileNav } from '../Header/Navigation/Navigation';
import { HeaderMenu } from '../Header/HeaderMenu/HeaderMenu';
import { MobileScreensContext } from '../../contexts/MobileScreensContext';
import { UserContext } from '../../contexts/UserContext';
import { EmptyContainer, LayoutContainer, ProfileContainer } from './Layout.styles';
import { useGlobals } from '../../hooks/useGlobals';
import { useHasMounted } from '../../hooks/useHasMounted';
import { LoadingBlurComponent } from '../Profiles/LoadingBlur';

export default function Layout({ children }) {
	const { showMobileProfileScreen, showMobileMenuScreen } = useContext(MobileScreensContext);
	const { isSelectingProfile, user, isLoadingProfile } = useContext(UserContext);
	const { i18n } = useTranslation();
	const globals = useGlobals(i18n.language);
	const hasMounted = useHasMounted();

	if (!hasMounted) {
		return null;
	}

	return !isSelectingProfile() ? (
		<>
			<Header />
			{showMobileMenuScreen && <MobileNav />}
			<LayoutContainer>{children}</LayoutContainer>
			{user && (
				<ProfileContainer show={showMobileProfileScreen}>
					<HeaderMenu />
				</ProfileContainer>
			)}
			<FooterComponent data={globals?.footer} />
			<LoadingBlurComponent show={isLoadingProfile()} />
		</>
	) : (
		<EmptyContainer>{children}</EmptyContainer>
	);
}
