import * as colors from './colors';
import * as layout from './layout';
import * as fonts from './fonts';
import * as misc from './misc';

export const theme = {
	colors,
	fonts: fonts.fonts,
	fontSizes: fonts.fontSizes,
	screenLayout: layout.screenLayout,
	screenLayoutGutters: layout.screenLayoutGutters,
	headerHeight: layout.headerHeight,
	misc,
};

export type ThemeInterface = typeof theme;
export type ThemeColorProp = keyof ThemeInterface['colors'];

declare module 'styled-components' {
	export interface DefaultTheme extends ThemeInterface {}
}
