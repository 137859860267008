import {
	Episode,
	ERROR_CODES,
	FileType,
	ImagePreset,
	License,
	Movie,
	Playable,
	Rental,
	factory,
	Request,
	FILE_QUERY,
	AlreadyPurchasedException,
	MissingMovieException,
	PurchaseLimitReached,
	RentalFailedException,
	AuthorizationException,
	AccountBlockingException,
	GeoBlockingException,
	NotPlayableException,
	MissingParameterException,
} from '@vodafoneis/sjonvarpskjarni-js-lib';
import PlaylistItem from './PlaylistItem';
import APIClient from '../api/APIClient';
import { client } from '../api/ApolloClient';
import { PlaybackType } from '../components/Player/PlaybackType';
// eslint-disable-next-line import/no-cycle
import { generateBasicInfoForPlaylistItem, track } from '../utils/Analytics';
import { AnalyticsEvent } from '../utils/AnalyticsEvent';
import { purchaseProduct } from '../api/product';
import { createEntitlement } from '../api/entitlement';
import { POSITION_UPDATE_INTERVAL } from '../config/constants';

const { HLS, DASH } = PlaybackType;

const { RENTAL_SUCCESS } = AnalyticsEvent;

export default class MoviePlaylistItem extends PlaylistItem {
	movie: Playable;
	license: License;
	rental: Rental;
	ageRating: number;

	constructor(movie: Playable, license?: License) {
		super();

		if (!movie) throw new MissingMovieException('Movie missing in constructor for MoviePlaylistItem');

		this.movie = movie;
		this.license = license ?? movie.getWatchableLicense();

		if (movie instanceof Episode) {
			this.ageRating = movie.series.ageRating;
		} else if (movie instanceof Movie) {
			this.ageRating = movie.ageRating;
		}

		const rental = this.license?.rental ?? null;
		if (rental && rental.isReady()) {
			this.rental = rental;
		}
	}

	getId() {
		return this.movie.id;
	}

	getContentTitle() {
		return this.movie.title;
	}

	getBackgroundImageUrl() {
		const imageParams = {
			presets: [ImagePreset.BACKDROP, ImagePreset.BACKDROP_OVERLAY],
		};

		if (this.movie instanceof Episode) {
			const stillImage = this.movie.getStillImageUrl(imageParams);
			if (stillImage) {
				return stillImage;
			}
		}

		return this.movie.getBackdropImageUrl(imageParams);
	}

	isLive() {
		return false;
	}

	isPlayable() {
		const { movie, license } = this;

		return !!movie.getFile(FileType.DASH) && !!license;
	}

	async createEntitlement(force = false) {
		if (this.entitlement) return this.entitlement;

		this.entitlement = await createEntitlement({
			movieId: this.movie.id,
			licenseId: this.license.id,
			force,
		});

		return this.entitlement;
	}

	requiresRental() {
		return this.license.isTVOD() || (this.license.isEST() && !this.license.isPurchased());
	}

	async createRental() {
		if (this.rental) return this.rental;

		if (!this.requiresRental()) return null;

		if (this.license.isEST() && !this.license.isPurchased()) {
			if (this.license.isPurchased()) {
				throw new AlreadyPurchasedException(`Tried to purchase movie through license ${this.license.id}, but it was already purchased`);
			}

			try {
				if (this.license.product) {
					this.license.purchase = await purchaseProduct(this.license.product.id, this.license.id);
				}
			} catch (exception) {
				console.error('License purchase failed', exception);

				if (exception instanceof PurchaseLimitReached) {
					throw exception;
				}
			}
		}

		const rentalResponse = await APIClient.post(
			new Request('rental', {
				body: {
					movieId: this.movie.id,
					licenseId: this.license.id,
				},
			})
		);

		if (rentalResponse.isSuccess()) {
			const rental = factory(rentalResponse.data);

			track(RENTAL_SUCCESS, {
				...generateBasicInfoForPlaylistItem(this),
				rentalId: rental.id,
				rentalWait: 0,
			});

			this.rental = rental;

			return rental;
		}

		if (rentalResponse.getErrorCode() === ERROR_CODES.ERROR_NOT_SUBSCRIBED) {
			throw new AuthorizationException(`Rental could not be created for movie ${this.getContentTitle()}, user is not subscribed to content`);
		} else if (rentalResponse.getErrorCode() === ERROR_CODES.ERROR_ACCOUNT_BLOCKING) {
			throw new AccountBlockingException();
		} else if (rentalResponse.getErrorCode() === ERROR_CODES.ERROR_GEO_BLOCKING) {
			throw new GeoBlockingException();
		} else if (rentalResponse.getErrorCode() === ERROR_CODES.ERROR_PURCHASE_LIMIT_REACHED) {
			throw new PurchaseLimitReached();
		}

		throw new RentalFailedException(`Could not create rental for movie ${this.getContentTitle()}`);
	}

	async preparePlayback() {
		if (this.isPrepared) return;

		await super.preparePlayback();

		if (!this.isPlayable()) {
			throw new NotPlayableException(`Movie not playable on device: ${this.getContentTitle()}`);
		}

		this.isPrepared = true;
	}

	async getUrl(playbackType: PlaybackType): Promise<string> {
		if (!playbackType) {
			throw new MissingParameterException('Missing parameter in MoviePlaylistItem - getUrl', 'playbackType');
		}

		const { data } = await client.query({
			query: FILE_QUERY,
			variables: { fileId: this.movie.getFile(FileType.DASH).id },
		});

		if (playbackType === DASH) {
			return PlaylistItem._prefixUrl(data.file.dashUrl);
		}

		if (playbackType === HLS) {
			return PlaylistItem._prefixUrl(data.file.hlsUrl);
		}

		return null;
	}

	getPositionToPlayFrom() {
		const position = this.movie.getPosition();

		if (position >= 30 && !this.movie.isConsideredFinished()) {
			return position;
		}

		return 0;
	}

	async savePosition(position: number, force = false) {
		super.savePosition(position);

		if (force || position % POSITION_UPDATE_INTERVAL === 0) {
			await APIClient.put(
				new Request('position/$$movieId$$', {
					pathVariables: {
						movieId: this.movie.id,
					},
					body: {
						position,
					},
				})
			);
		}
	}

	getVastUrl(): string {
		const { movie, license } = this;
		let url = null;

		if (license?.vast) {
			url = `https://ads.visir.is/vast/${license.vast.regionId}`;

			if (movie instanceof Episode && movie.series?.adTags?.length > 0) {
				url = `${url}/${movie.series.adTags.map((tag) => tag.externalId).join('/')}`;
			} else if (movie instanceof Movie && movie.adTags?.length > 0) {
				url = `${url}/${this.movie.adTags.map((tag) => tag.externalId).join('/')}`;
			}
		}

		return url;
	}
}
