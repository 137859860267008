export const screenLayout = Object.freeze({
	mobileSmall: 0,
	mobile: 390,
	tabletPortrait: 768,
	tabletLandscape: 1024,
	desktop: 1440,
	desktopLarge: 1920,
	desktopXLarge: 2560,
	oldLg: 992,
});

export const screenLayoutGutters = Object.freeze({
	mobileSmall: {
		right: 16,
		left: 16,
	},
	mobile: {
		right: 16,
		left: 16,
	},
	tabletPortrait: {
		right: 20,
		left: 20,
	},
	tabletLandscape: {
		right: 40,
		left: 40,
	},
	desktop: {
		right: 62,
		left: 62,
	},
	desktopLarge: {
		right: 84,
		left: 84,
	},
	desktopXLarge: {
		right: 4.37,
		left: 4.37,
	},
});

export const headerHeight = Object.freeze({
	mobileSmall: 127,
	mobile: 127,
	tabletPortrait: 127,
	tabletLandscape: 86,
	desktop: 120,
	desktopLarge: 120,
	desktopXLarge: 160,
});
