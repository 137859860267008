import React, { createContext, useState, useReducer, useMemo, Dispatch } from 'react';
import { useRouter } from 'next/router';
import { FORGOT_ACTIONS } from '../scenes/ForgotScene/ForgotConstants';

type ForgotContextProps = {
	loading: boolean;
	setLoading: (loading: boolean) => void;
	step: number;
	dispatch: Dispatch<any>;
};

export const ForgotContext = createContext<Partial<ForgotContextProps>>({});

const { RESET, PREV_STEP, NEXT_STEP, SET_STEP, LOGIN, SUCCESS } = FORGOT_ACTIONS;

function reducer(state, action) {
	const { type, step: stepToSet } = action;
	const { step, router } = state;
	switch (type) {
		case RESET:
			return { ...state, step: 1 };
		case PREV_STEP:
			return { ...state, step: step - 1 };
		case NEXT_STEP:
			return { ...state, step: step + 1 };
		case SET_STEP:
			return { ...state, step: stepToSet || 1 };
		case LOGIN:
			router.replace('/login');
			return state;
		case SUCCESS:
			router.replace('/');
			return state;
		default:
			return state;
	}
}

export const ForgotContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [loading, setLoading] = useState(null);

	const router = useRouter();
	const [{ step }, dispatch] = useReducer(reducer, { step: 1, router });

	const value = useMemo(() => {
		return {
			loading,
			setLoading,
			step,
			dispatch,
		};
	}, [loading, step]);

	return <ForgotContext.Provider value={value}>{children}</ForgotContext.Provider>;
};
