import React, { SyntheticEvent } from 'react';
import { ExpandButton, MenuHeadContainer, MenuTitle } from './Footer.styles';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';

type MenuHeadProps = {
    title: string;
    isOn?: boolean;
    onClick?: (event: React.SyntheticEvent) => void;
};

export const MenuHead: React.FC<MenuHeadProps> = ({ title, isOn, onClick }) => {

	return (
		<MenuHeadContainer onClick={onClick}>
			<MenuTitle>
				{title}
			</MenuTitle>
			<ExpandButton isOn={isOn}>
				<Icon type={ICON_TYPE.ARROW_DOWN} />
			</ExpandButton>
		</MenuHeadContainer>
	);
};
