export const white = '#FFFFFF';
export const black = '#000000';
export const richBlack = '#021818';
export const gray = '#E0E0E0';
export const darkGray = '#181717;';
export const inherit = 'inherit';
export const transparent = 'transparent';
export const teal = '#205257';
export const tealLight = '#26B3BA';
export const orange = '#E18008';
export const passionPurple = '#D4145A';
export const passionPurpleDark = '#AE0946';
export const tealPlus20 = '#4D7579';
export const tealPlus40 = '#79979A';
export const tealPlus60 = '#A6BABC';
export const tealPlus80 = '#D2DCDD';
export const tealMinus20 = '#1A4246';
export const tealMinus40 = '#133134';
export const tealMinus60 = '#0D2123';
export const tealMinus70 = '#0A191A';
export const tealMinus80 = '#061011';
export const btnPrimaryLight = '#1E8F95';
export const btnPrimary = '#176B70';
export const textSecondary = '#ffffff12';
export const tealHover = '#10292C';
export const none = 'none';
