import React from 'react';
import styled from 'styled-components';
import { HeaderMenuIconContainer, HeaderMenuIconContainerClickHandler } from './HeaderMenu/HeaderMenuIconContainer';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';

const ProfilePhoto = styled.img`
	width: 100%;
	height: 100%;
`;

const IconContainer = styled.div`
	justify-content: center;
	align-items: center;

	svg {
		transform: scale(0.7);
	}
`;

export const ProfileImage: React.FC<{ image: string }> = ({ image }) => {
	return (
		<HeaderMenuIconContainer>
			{image ? (
				<ProfilePhoto src={image} />
			) : (
				<IconContainer>
					<Icon type={ICON_TYPE.HOME} />
				</IconContainer>
			)}
		</HeaderMenuIconContainer>
	);
};

type ProfileImageClickHandlerProps = {
	userImage: string;
	handleClick: () => void;
};
export const ProfileImageClickHandler: React.FC<ProfileImageClickHandlerProps> = ({ userImage, handleClick }) => {
	return (
		<HeaderMenuIconContainerClickHandler handleClick={handleClick}>
			{userImage ? (
				<ProfilePhoto src={userImage} />
			) : (
				<IconContainer>
					<Icon type={ICON_TYPE.HOME} />
				</IconContainer>
			)}
		</HeaderMenuIconContainerClickHandler>
	);
};
