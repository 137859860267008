export const fonts = {
	standard: 'Vodafone, "Helvetica Neue", sans-serif',
	albertSans: '"Albert Sans", "Helvetica Neue", sans-serif',
};

export const fontSizes = {
	14: '0.875rem',
	16: '1rem',
	18: '1.125rem',
	20: '1.25rem',
	22: '1.375rem',
	24: '1.5rem',
	28: '1.75rem',
	52: '3.25rem',
	56: '3.5rem',
	80: '5rem',
};
