import Link from 'next/link';
import React from 'react';
import Image from 'next/image';
import {
	CompanyLogo,
	CopyrightText,
	FooterBottom,
	FooterContainer,
	FooterMain,
	FooterMenu,
	FooterTop,
	InfoIdentity,
	InfoLocation,
	InfoMain,
	InfoTextLeft,
	InfoTextRight,
	LinkFrame,
	LinksContainer,
	LinkTextSmall,
	SocialContainer,
	SocialIconImageContainer
} from './Footer.styles';

import { MenuPanel } from './MenuPanel';
import FooterModel from '../../models/strapi/FooterModel';

type FooterComponentProps = {
	data: FooterModel
};
export const FooterComponent: React.FC<FooterComponentProps> = ({ data }) => {
	const year = new Date().getFullYear();
	// Render
	return (
		<FooterContainer>
			<FooterTop>
				<FooterMain>
					{data?.logo?.data?.attributes?.url && (
						<CompanyLogo>
							<Image layout="fill" objectFit="contain" src={data?.logo?.data?.attributes?.url} alt={data?.logo?.data?.attributes?.alternativeText} />
						</CompanyLogo>
					)					}
					<InfoMain>
						<InfoLocation>
							{data?.companyInfoText?.split('\n')?.map((text, index) => {
								// eslint-disable-next-line react/no-array-index-key
								return <InfoTextLeft key={index}>{text}</InfoTextLeft>;
							})}
						</InfoLocation>
						<InfoIdentity>
							<InfoTextRight>{data?.companySsnField}</InfoTextRight>
							<InfoTextRight>{data?.companyVskField}</InfoTextRight>
						</InfoIdentity>
					</InfoMain>
				</FooterMain>
				<FooterMenu>
					{data?.columns.map((col, index) => {
						return (
							// eslint-disable-next-line react/no-array-index-key
							<MenuPanel key={`${index}`} headTitle={col.title} links={col.links} />
						);
					})}
				</FooterMenu>
			</FooterTop>
			<FooterBottom>
				<SocialContainer>
					{data?.iconlinks?.length > 0 && data?.iconlinks?.map((iconlink) => {
						return (

							<Link href={iconlink.url} key={iconlink.id}>
								<a href={iconlink.url} target="_blank" rel="noopener noreferrer">
									<SocialIconImageContainer>
										<Image layout="fill" objectFit="contain" src={iconlink?.icon?.data?.attributes?.url} alt={iconlink?.icon?.data?.attributes?.alternativeText} />
									</SocialIconImageContainer>
								</a>
							</Link>

						);
					})}
				</SocialContainer>
				<LinksContainer>
					<CopyrightText>
						© Sýn hf.
						{year}
					</CopyrightText>

					{data?.bottomlinks?.length > 0 && data?.bottomlinks?.map((l, index) => {
						return (
						// eslint-disable-next-line react/no-array-index-key
							<LinkFrame key={index}>
								<Link href={l?.url}>
									<LinkTextSmall href={l?.url} target={l?.newTab ? '_blank' : '_self'} rel="noopener noreferrer">
										{l?.text}
									</LinkTextSmall>
								</Link>
							</LinkFrame>
						);
					})}

				</LinksContainer>
			</FooterBottom>
		</FooterContainer>
	);
};
