import React, { FC } from 'react';
import styled from 'styled-components';
import { resolveHiddenBreakpoints, ThemeBreakpoints } from '../../utils/media';

interface HiddenProps {
	above?: keyof ThemeBreakpoints;
	below?: keyof ThemeBreakpoints;
	children: React.ReactNode;
}

const StyleHidden = styled.div<HiddenProps>`
	${({ above, below }) => resolveHiddenBreakpoints(above, below)};
`;

export const Hidden: FC<HiddenProps> = ({ above, below, children }) => {
	return (
		<StyleHidden above={above} below={below}>
			{children}
		</StyleHidden>
	);
};
