import { Profile, ProfileType } from '@vodafoneis/sjonvarpskjarni-js-lib';

const DEFAULT_PROFILE_PREFIX = 'default';
export const DEFAULT_PROFILE_NAME = 'Heima';
const BANNED_CHILDREN_PATH = ['/genre/rent', '/genre/sports', '/live', '/', '/settings'];
const ALLOWED_WITHOUT_PROFILE = ['/link'];

export default class ProfileUtils {
	static isDefaultOrNulledProfile(profile?: Profile) {
		return !profile || profile?.id.includes(DEFAULT_PROFILE_PREFIX);
	}
	static getProfileName(profile?: Profile) {
		if (this.isDefaultOrNulledProfile(profile)) {
			return DEFAULT_PROFILE_NAME;
		}
		return profile?.name;
	}
	static isPageAllowedWithoutProfile(pagePath?: string) {
		return ALLOWED_WITHOUT_PROFILE.includes(pagePath);
	}
	static isPageBannedForProfile(profile?: Profile, pagePath?: string) {
		if (!profile || profile?.type === ProfileType.NORMAL) {
			return false;
		}
		return BANNED_CHILDREN_PATH.includes(pagePath);
	}
}
