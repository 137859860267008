export const lightGradient = 'linear-gradient(259.82deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #27868B';
export const tailGradiant = 'linear-gradient(260.04deg, rgba(255, 255, 255, 0.04) -0.21%, rgba(255, 255, 255, 0) 104.36%), #1A4246';
export const gradient = 'linear-gradient(217.32deg, #0D2123 3.28%, #000000 67.97%), #000000';
export const gradientDarkBottom = 'linear-gradient(180deg, #0D2123 3.28%, #000000 67.97%), #000000';
export const rightOverLayGradient = 'linear-gradient(259.82deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%)';
export const boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 11px 15px -7px rgba(0, 0, 0, 0.3), 0px 24px 38px 3px rgba(0, 0, 0, 0.24)';
export const h1TextShadow = '0px 1px 4px rgba(0, 0, 0, 0.25)';
export const h6TextShadow = '0px 1px 2px rgba(0, 0, 0, 0.2)';
export const introTextShadow = '0px 1px 4px rgba(0, 0, 0, 0.25)';
export const bodySmallTextShadow = '0px 1px 2px rgba(0, 0, 0, 0.2)';
export const blackTransparent = 'rgba(255, 255, 255, 0.1)';
export const blackMoreTransparent = 'rgba(255, 255, 255, 0.04)';
export const blackMoreTransparentHover = 'rgba(255, 255, 255, 0.055)';
export const blackMoreTransparentActive = 'rgba(255, 255, 255, 0.07)';
export const bgGradient = 'linear-gradient(217.32deg, #0D2123 3.28%, #000000 67.97%), #000000';
export const TEAL_PLUS_40_MIXIN = 'invert(60%) sepia(13%) saturate(463%) hue-rotate(137deg) brightness(92%) contrast(96%)';
export const boxShadowHigh = ' 0px 11px 15px -7px rgba(0, 0, 0, 0.3), 0px 24px 38px 3px rgba(0, 0, 0, 0.24), 0px 9px 46px 8px rgba(0, 0, 0, 0.22)';
export const tealPlus40Mixin = 'invert(60%) sepia(13%) saturate(463%) hue-rotate(137deg) brightness(92%) contrast(96%)';
export const textGradient = 'linear-gradient(268.51deg, #205257 0%, #0a191a 100%)';
export const tealGradient = 'linear-gradient(180deg, rgba(32, 82, 87, 0) 50%, #205257 100%), linear-gradient(90deg, rgba(32, 82, 87, 0.75) 0%, rgba(32, 82, 87, 0) 50%)';
