import React, { useState } from 'react';
import User from '../../../models/User';
import { HeaderMenu } from '../HeaderMenu/HeaderMenu';
import { ProfileImage } from '../ProfileImage';
import { Icon, ICON_TYPE } from '../../IconComponent/IconComponent';
import { CurrentUserContainer, CurrentUserName, HeaderMenuContainerRel, HeaderMenuContainer } from './CurrentUser.styles';

type Props = {
	user: User;
};

export const CurrentUser: React.FC<Props> = ({ user }) => {
	const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
	return (
		<CurrentUserContainer onMouseEnter={() => setHeaderMenuOpen(true)} onMouseLeave={() => setHeaderMenuOpen(false)}>
			<ProfileImage image={user?.selectedProfile?.image} />
			<CurrentUserName truncate>{user?.selectedProfile?.name?.split(' ')[0]}</CurrentUserName>
			<Icon type={ICON_TYPE.ARROW_DOWN} />
			{headerMenuOpen && (
				<HeaderMenuContainerRel>
					<HeaderMenuContainer>
						<HeaderMenu />
					</HeaderMenuContainer>
				</HeaderMenuContainerRel>
			)}
		</CurrentUserContainer>
	);
};
