import styled, { css } from 'styled-components';
import { media } from '../../ui/utils/media';

const footerStyle = css`
	font-family: 'Albert Sans';
	font-style: normal;
	letter-spacing: -0.01em;
`;

const InfoText = css`
	${footerStyle};
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	margin: 0;

	${media.tabletLandscapeUp} {
		line-height: 16.8px;
	}

	${media.desktopUp} {
		font-size: 16px;
		line-height: 19.2px;
	}

	${media.desktopXLargeUp} {
		font-size: 21.33px;
		line-height: 25.6px;
	}
`;

export const InfoTextLeft = styled.p`
	${InfoText};
	text-align: left;
`;

export const InfoTextRight = styled.p`
	${InfoText};
	text-align: right;
`;

export const MenuTitle = styled.h6`
	${footerStyle};
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.white};

	${media.tabletLandscapeUp} {
		font-size: 16px;
		line-height: 19.2px;
	}

	${media.desktopUp} {
		font-size: 18px;
		line-height: 21.6px;
	}

	${media.desktopXLargeUp} {
		font-size: 24px;
		line-height: 28.8px;
	}
`;

const linkText = css`
	${footerStyle};
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;

	${media.tabletLandscapeUp} {
		font-size: 14px;
		line-height: 16.8px;
	}

	${media.desktopUp} {
		font-size: 16px;
		line-height: 19.2px;
	}

	${media.desktopXLargeUp} {
		font-size: 21.33px;
		line-height: 25.6px;
	}
`;

export const LinkText = styled.a<{ isDisabled?: boolean }>`
	${linkText};
	color: ${({ theme }) => theme.colors.tealPlus20};

	&:hover {
		color: ${({ theme }) => theme.colors.tealPlus20};
	}

	pointer-events: ${(props) => (props.isDisabled ? 'none' : 'all')};
`;

export const LinkTextWhite = styled.span`
	${linkText};
	color: ${({ theme }) => theme.colors.white};

	&:hover {
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const LinkTextSmall = styled.a`
	${footerStyle};
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${({ theme }) => theme.colors.tealPlus20};

	&:hover {
		color: ${({ theme }) => theme.colors.tealPlus20};
	}

	${media.desktopXLargeUp} {
		font-size: 18.67px;
		line-height: 22.4px;
	}
`;

export const CopyrightText = styled.p`
	${footerStyle};
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: -0.01em;
	margin: 0;
	color: ${({ theme }) => theme.colors.tealPlus20};

	${media.desktopXLargeUp} {
		font-size: 18.67px;
		line-height: 22.4px;
	}
`;

export const FooterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	padding: 16px 0;
	padding-left: ${({ theme }) => theme.screenLayoutGutters.mobile.left}px;
	gap: 16px;
	background: ${({ theme }) => theme.misc.gradientDarkBottom};

	${media.tabletPortraitUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.tabletPortrait.left}px;
	}

	${media.tabletLandscapeUp} {
		padding: 50px;
		padding-left: ${({ theme }) => theme.screenLayoutGutters.tabletLandscape.left}px;
	}

	${media.desktopUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktop.left}px;
	}

	${media.desktopLargeUp} {
		padding: 90px;
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktopLarge.left}px;
	}

	${media.desktopXLargeUp} {
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktopXLarge.left}rem;
	}
`;

export const FooterTop = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	${media.tabletLandscapeUp} {
		flex-direction: row;
		justify-content: space-between;

		padding-bottom: 70px;
		border-bottom: 1px solid ${({ theme }) => theme.misc.blackTransparent};
	}

	${media.desktopUp} {
		padding: 0px 90px 50px 0px;
	}
`;

export const FooterMain = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 16px 16px 0px;
	padding-left: 0px;
	gap: 20px;
	position: relative;

	${media.tabletLandscapeUp} {
		gap: 50px;
	}
`;

export const CompanyLogo = styled.div`
	position: relative;
	padding-right: 24px;
	display: flex;
	width: 56.35px;
	height: 46px;

	${media.tabletLandscapeUp} {
		width: 108px;
	}

	${media.desktopXLargeUp} {
		width: 144px;
	}
`;

export const InfoMain = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0px;
	gap: 40px;

	${media.tabletLandscapeUp} {
		flex-direction: column;
		gap: 20px;
	}
`;

export const InfoLocation = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const InfoIdentity = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 0;

	${media.tabletLandscapeUp} {
		flex-direction: row;
		gap: 40px;
	}
`;

export const FooterMenu = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex: 2;
	padding: 0px 16px;
	padding-left: 0px;
	gap: 0px;
	order: -1;

	${media.tabletLandscapeUp} {
		flex-direction: row;
		order: 0;
		gap: 90px;
	}

	${media.desktopUp} {
		gap: 140px;
	}

	${media.desktopXLargeUp} {
		gap: 180px;
	}
`;

export const MenuPanelContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${({ theme }) => theme.misc.blackTransparent};
	padding: 16px 0px;

	${media.tabletLandscapeUp} {
		border-bottom: 0px;

		padding: 0;
	}
`;

export const MenuHeadContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow: hidden;
	padding-top: 16px;

	&:hover {
		cursor: pointer;
	}

	${media.tabletLandscapeUp} {
		pointer-events: none;
	}
`;

export const ExpandButton = styled.div<{ isOn?: boolean }>`
	width: 25px;
	height: 25px;
	transform: rotate(${(props) => (props.isOn ? '-180deg' : '0deg')});
	transition: transform 0.3s ease-in-out;

	${media.tabletLandscapeUp} {
		display: none;
	}
`;

export const MenuItems = styled.div<{ expanded?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow: hidden;
	max-height: ${(props) => (props.expanded ? '500px' : '0')};
	opacity: ${(props) => (props.expanded ? '1' : '0')};
	padding: ${(props) => (props.expanded ? '16px 0px' : '0')};
	gap: 20px;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;

	${media.tabletLandscapeUp} {
		max-height: 500px;
		opacity: 1;
		padding: 30px 0px;
	}

	${media.desktopXLargeUp} {
		padding: 40px 0px;
	}
`;

export const FooterBottom = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	${media.tabletLandscapeUp} {
		flex-direction: row;
		justify-content: space-between;
	}

	${media.desktopUp} {
		padding: 0px 90px 0px 0px;
	}
`;

export const SocialContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 16px;
	gap: 24px;
	position: relative;
`;
export const SocialIconImageContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
`;

export const LinksContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 16px;
	gap: 8px;

	${media.tabletLandscapeUp} {
		flex-direction: row;
		gap: 64px;
	}

	${media.desktopXLargeUp} {
		gap: 85.33px;
	}
`;

export const LinkFrame = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
	gap: 24px;

	${media.tabletLandscapeUp} {
		gap: 64px;
	}
`;
