import styled from 'styled-components';
import { sharedFontStyles } from '../../ui/core/Typography/typography.styles';
import { media } from '../../ui/utils/media';
import { ButtonLink } from '../ButtonLink';

export const HeaderContainer = styled.div`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
	height: ${({ theme }) => theme.headerHeight.mobile}px;
	padding-left: ${({ theme }) => theme.screenLayoutGutters.mobile.left}px;
	padding-right: ${({ theme }) => theme.screenLayoutGutters.mobile.right}px;
	${media.tabletPortraitUp} {
		height: ${({ theme }) => theme.headerHeight.tabletPortrait}px;
		padding-left: ${({ theme }) => theme.screenLayoutGutters.tabletPortrait.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.tabletPortrait.right}px;
	}
	${media.tabletLandscapeUp} {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
		height: ${({ theme }) => theme.headerHeight.tabletLandscape}px;
		padding-left: ${({ theme }) => theme.screenLayoutGutters.tabletLandscape.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.tabletLandscape.right}px;
	}
	${media.desktopUp} {
		height: ${({ theme }) => theme.headerHeight.desktop}px;

		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktop.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktop.right}px;
	}
	${media.desktopLargeUp} {
		height: ${({ theme }) => theme.headerHeight.desktopLarge}px;
		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktopLarge.left}px;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktopLarge.right}px;
	}
	${media.desktopXLargeUp} {
		height: ${({ theme }) => theme.headerHeight.desktopXLarge}px;

		padding-left: ${({ theme }) => theme.screenLayoutGutters.desktopXLarge.left}vw;
		padding-right: ${({ theme }) => theme.screenLayoutGutters.desktopXLarge.right}vw;
	}
`;

export const HeaderInnerContainer = styled.div`
	display: flex;
	position: relative;
	height: 64px;
	width: 100%;
	margin-top: 0px;
	padding-top: 16px;
	${media.tabletPortraitUp} {
		padding-top: 16px;
	}
	${media.tabletLandscapeUp} {
		height: 100%;
		padding-top: 22.68px;
	}
	${media.desktopUp} {
		padding-top: 32px;
	}
	${media.desktopXLargeUp} {
		padding-top: 42.67px;
	}
`;

export const MenuContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	height: 100%;
`;

export const LeftMenuItemContainer = styled.div`
	flex: 1;
`;

export const RightMenuItemContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;

export const MiddleItemContainer = styled.div`
	flex: 2;
`;

export const MenuLogoContainer = styled.div`
	display: flex;
	cursor: pointer;
	height: 32px;
	width: 56.62px;
	${media.tabletLandscape} {
		height: 28.44px;
		width: 50.32px;
	}
	${media.desktopUp} {
		height: 40px;
		width: 70.77px;
	}
	${media.desktopXLargeUp} {
		height: 53.3px;
		width: 90.36px;
	}
`;

export const LoginLogoutButton = styled(ButtonLink)`
	display: flex;
	${sharedFontStyles};
	font-weight: 700;
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	${media.desktopUp} {
		font-size: 18px;
		line-height: 22px;
	}
	${media.desktopXLargeUp} {
		font-size: 24px;
		line-height: 29px;
	}
	${media.tabletLandscapeUp} {
		margin-right: 20px;
	}
`;

export const RightContainer = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: flex-end;
`;

export const SearchLink = styled.a`
	margin-right: 22px;
	${media.desktopUp} {
		margin-right: 31px;
	}

	${media.desktopXLargeUp} {
		margin-right: 41px;
	}
`;
